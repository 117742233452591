/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    loading: false,
    subAccountDetails: {},
    apiKey: {},
    viber: [],
    currentData: {},
    previousData: {},
    reports: [],
    logData: [],
  },

  getters: {
    loading: st => st.loading,
    getSubAccountDetails: st => st.subAccountDetails,
    getApiKey: st => st.apiKey,
    getCurrentData: st => st.currentData,
    getPreviousData: st => st.previousData,
    getReports: st => st.reports,
    getLogData: st => st.logData,
  },

  mutations: {
    SET_LOADING: (st, payload) => { st.loading = payload.loading; },
    SET_VERIF8_SUBACCOUNT: (st, payload) => { st.subAccountDetails = payload.subAccount; },
    ADD_VERIF8_DETAILS: (st, payload) => { st.verif8Details = payload.subAccountDetails; },
    SET_VERIF8_APIKEY: (st, payload) => { st.apiKey = payload.key; },
    SET_VERIF8_VIBER: (st, payload) => { st.viber = payload.viber; },
    SET_CURRENT_VERIF8_REPORT: (st, payload) => { st.currentData = payload.currentData; },
    SET_PREVIOUS_VERIF8_REPORT: (st, payload) => { st.previousData = payload.previousData; },
    SET_REPORT_RESULTS: (st, payload) => { st.reports = payload; },
    SET_VERIF8_LOG_DATA: (st, payload) => { st.logData = payload; },
  },

  actions,
};
