//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Verif8_GetStarted',
  data() {
    return {
      showApiKey: false,
      maskedApiKey: '*******************************************',
    };
  },
  async mounted() {
    await this.getVerif8Details();
    if (!this.subAccountDetails.SubAccountId && !this.apiKey.ApiKey) {
      this.$router.push({ name: '/verif8' });
      return;
    }
    await this.toggleView();
  },
  created() {},
  computed: {
    ...mapGetters({
      subAccountDetails: 'verif8/getSubAccountDetails',
      apiKey: 'verif8/getApiKey',
    }),
  },
  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
    }),
    goDevDoc() {
      if (!!window) {
        window.open('https://developer.8x8.com/connect/reference/verify-request-v2', '_blank');
      }
    },
    showApiKey() {
      return this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
    },
    toggleView() {
      this.maskedApiKey = this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
      this.showApiKey = !this.showApiKey;
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey.ApiKey);
      this.$message.success('Copied to Clipboard');
    },
  },
};
