<template>
  <el-dialog
    title="Export reports"
    class="export-modal"
    :visible.sync="showDlModal"
    :before-close="closeDialog">
    <span
      slot="footer"
      class="dialog-footer">
      <el-button
        type="primary"
        size="mini"
        :loading="loading"
        @click="() => {}">
        Download
      </el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    showDlModal: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },

  data() {

    return {

    };
  },

  computed: {
    ...mapGetters({
      
    }),
  },

  watch: {

  },

  mounted() {},

  methods: {
    closeDialog() {
      this.showDlModal = false;
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss">
.export-modal .el-dialog__body {
  padding: 10px 20px;
}
</style>