import http from '../../../../utils/http';


export default {
  // Fetch Operator Lists
  async getOperatorLists({ commit }, payload) {
    commit({ type: 'SET_LOADING', payload: true });
    const searchParams = {};

    for (const key in payload) { // eslint-disable-line
      if (payload[key] !== null) {
        switch (key) {
        case 'dateRange':
        case 'dateFromTo':
          if (payload[key].length === 0) break;
          searchParams.dateFrom = payload[key][0]; // eslint-disable-line
          searchParams.dateTo = payload[key][1]; // eslint-disable-line
          break;
        case 'useUTC':
          searchParams.utcOffset = !payload[key] ? Math.abs(new Date().getTimezoneOffset()) : 0; // eslint-disable-line
          break;
        case 'country':
          searchParams.country = payload[key];
          break;
        default:
          if (typeof payload[key] !== 'undefined') {
            searchParams[key] = payload[key];
          }
          break;
        }
      }
    }
    const newQueryParams = new URLSearchParams(searchParams);

    try {
      const { data: response } = await http.v1.get(`reports/operators?${newQueryParams.toString()}`);
      commit('SET_OPERATOR_LISTS', response);
      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },
  // Fetch Country Lists
  async getCountryLists({ commit }, payload) {
    commit({ type: 'SET_LOADING', payload: true });
    const searchParams = {};

    for (const key in payload) { // eslint-disable-line
      if (payload[key] !== null) {
        switch (key) {
        case 'dateFromTo':
          if (payload[key].length === 0) break;
          searchParams.dateFrom = payload[key][0]; // eslint-disable-line
          searchParams.dateTo = payload[key][1]; // eslint-disable-line
          break;
        case 'useUTC':
          searchParams.utcOffset = !payload[key] ? Math.abs(new Date().getTimezoneOffset()) : 0; // eslint-disable-line
          break;
        case 'country':
        case 'operator':
          // Not Used in the API
          break;
        default:
          if (typeof payload[key] !== 'undefined') {
            searchParams[key] = payload[key];
          }
          break;
        }
      }
    }
    const newQueryParams = new URLSearchParams(searchParams);

    try {
      const { data: response } = await http.v1.get(`reports/countries?${newQueryParams.toString()}`);
      commit('SET_COUNTRY_LISTS', response);
      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },

  // Fetch user info
  getUser({ commit }) {
    try {
      const cpv3User = localStorage.getItem('CPV3_User') || {};

      const userData = JSON.parse(cpv3User);
      const token = localStorage.getItem('WWW-Authenticate');
      const user = { ...userData, Token: token };

      commit('SET_USER', { user });

      return user;
    } catch (err) {
      commit('API_ERROR', err, { root: true });

      return err;
    }
  },
  async getAuthUser({ commit }) {
    try {
      const { data } = await http.v1.get('auth/user?rolesVersion=2');
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    }
  },
};
