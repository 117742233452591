//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Verif8_Step2',
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    subAccountInfo: {
      type: Object,
      default() {
        return {
          subAccountId: '',
          subAccountUid: '',
        };
      }
    },
    senderId: {
      type: String,
      default() {
        return 'Verif8';
      }
    },
    senderId: {
      type: String,
      default() {
        return 'Verif8';
      }
    }
  },

  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
      apiKey: 'verif8/getApiKey',
    }),
  },

  data() {
    return {
      apiKeyName: 'Verif8_Default',
      showApiKey: false,
      maskedApiKey: '*******************************************',
    };
  },


  async mounted() {
    const { data } = await this.getAuthUser();
    
    if (data) {
      const event = new Event('verif8:success-onboarding');
      window.dispatchEvent(event);
      localStorage.setItem('CPV3_User', JSON.stringify(data));
    }
  },

  methods: {
    ...mapActions({
      getAuthUser: 'user/getAuthUser',
    }),
    nextStep() {
      this.$emit('finish-step');
    },
    backStep() {
      this.$emit('back-step');
    },
    generateKey() {
      this.$emit('generate-key', { name: this.apiKeyName });
    },
    toggleView() {
      this.maskedApiKey = this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
      this.showApiKey = !this.showApiKey;
      console.log(this.maskedApiKey);
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey.ApiKey);
      this.$message.success('Copied to Clipboard');
    },
  },
};
