//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
    name: 'SmsConversionTable',
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 10,
            pageNo: 1,
            total: 1
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.data.length / parseInt(this.pageSize, 10));
        },

        getPaginatedData() {
            const start = (this.pageNo - 1) * this.pageSize;
            return this.data.slice(start, start + this.pageSize);
        },
    },
    methods: {
        handleSizeChange(size) {
            this.pageSize = Number(size)
        },
        handleSelectPage(pn) {
            this.pageNo = Number(pn);
        },
        handlePaginationChange(action) {
            let page = this.pageNo;
            if (action === 'next') {
                page += 1;
            }

            if (action === 'prev' && page) {
                page -= 1;
            }

            this.pageNo = Number(page);
        },
        setPage(page) {
            this.pageNo = page;
        },
        genericSort(a, b, column) {
            return a[column] - b[column];
        },
        sortDate(a, b) {
            return new Date(a.TimeFrom) - new Date(b.TimeFrom);
        }
    },
    mounted() {
    }
}
