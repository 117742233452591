/* eslint-disable no-param-reassign */
import actions from './actions';

export default {
  namespaced: true,

  state: {
    user: {},
    loading: true,
    operatorLists: [],
    countryLists: [],
  },

  getters: {
    getLoading: st => st.loading,
    getUser: st => st.user,
    getOperators: st => st.operatorLists,
    getCountries: st => st.countryLists,
  },

  mutations: {
    SET_LOADING: (st, payload) => { st.loading = payload; },
    SET_OPERATOR_LISTS: (st, payload) => { st.operatorLists = payload; },
    SET_COUNTRY_LISTS: (st, payload) => { st.countryLists = payload; },
    SET_USER(state, payload) {
      // eslint-disable-next-line no-param-reassign
      state.user = payload.user;

      const {
        Product_SMS: hasSMSAccess,
        Product_CA: hasCAAccess,
        Product_VO: hasVoiceAccess,
        SmsToSurveyEnabled: hasSurvey,
        HasSubAccountsWithSurveyForms: hasSurveyForms,
        BetaFeatures: betaFeatures,
      } = payload.user;

      state.hasSmsAccess = hasSMSAccess;

      state.hasChatAppsAccess = hasCAAccess;

      state.hasVoiceAccess = hasVoiceAccess && betaFeatures.includes('VO-Messaging');

      state.hasSmsEngageAccess = hasSMSAccess
        && hasSurvey && hasSurveyForms;
    },

  },

  actions,
};
