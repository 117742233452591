<template>
  <section id="verif8-reports">
    <div class="flex inline-block w-full">
        <h1 class="w-4/5 text-xl text-color font-semibold page-title">
            Verif8 · Reports <span class="text-md"><i class="icon-novo-info-circle"></i></span>
        </h1>
        <div class="w-1/5 text-right">
            <el-button 
                size="sm"
                type="default"
                class="inline-flex items-center mt-2"
                style="background-color:#E0E0E0;border-color:#E0E0E0"
                @click="showHideFilter"
            >
                <span class="text-md text-right" style="color:#292929"><i class="icon-novo-filter"></i></span>
                <span class="absolute -mt-4 px-2 py-1 text-xs text-black rounded-full" style="background-color:#F8AE1A" v-if="filterCount !== 0">
                  {{ filterCount }}
                </span>
            </el-button>
            <el-button 
                size="sm"
                type="primary"
                v-if="false" 
                @click="exportLogs"
            >
                <span class="text-md text-right"><i class="icon-novo-download"></i> Export</span>
            </el-button>
        </div>
    </div>
    <div class="reports-filter-container mb-5 el-main p-0 bg-white p-3 mt-2 rounded py-3" v-if="showFilter">
        <div class="flex w-full items-center">
            <div class="3/5">
                <el-date-picker
                    type="daterange"
                    format="dd MMM yyyy"
                    value-format="yyyy-MM-dd"
                    start-placeholder="Date Start"
                    end-placeholder="Date End"
                    :clearable="false"
                    :editable="false"
                    align="left"
                    size="small"
                    :picker-options="pickerOptions"
                    v-model="dateFromTo"
                    @change="dateChange"
                    @pick="dateChange"
                >
                </el-date-picker>
                <el-select placeholder="Country" size="small" @change="countryChanged" v-model="country">
                    <el-option
                        v-for="cty in countryList"
                        :key="cty.Country"
                        :label="cty.CountryName"
                        :value="cty.Country"
                    />
                </el-select>
                <el-select placeholder="Operator" size="small" @change="operaterChanged" v-model="operator" :key="country">
                    <el-option
                        v-for="op in operatorList"
                        v-if="op.Country === country || country === ''"
                        :key="op.OperatorId"
                        :label="op.OperatorName"
                        :value="op.OperatorId"
                    />
                </el-select>
                <el-switch 
                  :value="useUTC"
                  active-text="Use UTC Timezone"
                  @change="changeTimezone" 
                />
            </div>
            <div class="ml-auto 2/5 text-right">
                <a href="javascript:void(0)" class="no-underline text-blue-light" @click="resetFilters" >Reset</a>
                <el-button :disabled="disableApply" type="primary" size="xs" class="ml-3" @click="applyFilter(true)">
                    Apply 
                </el-button>
            </div>
        </div>
    </div>
     <div class="bg-white mt-5 mb-5 w-full rounded relative graph-container">
      <div class="flex items-center graph-container-header">
        <div class="flex items-center">
          <h4>SMS Conversion Statuses</h4>
          <el-tooltip
              :content="'Shows the breakdown of SMS recipients by their conversion status: Converted, Rejected, Sent, and Converted Rate.'"
              placement="top-start"
              class="text-left pl-2"
            >
              <span
                class="icon-novo-faq text-base cursor-pointer hover-icon"

              />
          </el-tooltip>
        </div>
      </div>
      <SmsConversionStats v-loading="loading" :key="nextTickKey" :chartData="getSMSConversionStats()"/>
    </div>

    <div class="mb-5 el-main p-0 bg-white h-min pt-3 mt-2 rounded pb-10 graph-container graph-container-sms-conversion">
      <div class="flex inline-block w-full sms-conversions-table-header">
        <h3 class="text-md">SMS Conversions</h3>
        <div class="ml-auto w-1/5 text-right items-center">
          <span class="text-sm text-stone-500 text-center">{{ `${reports && reports.tableData && reports.tableData.length} result(s)` }}  </span>
          <el-divider
            direction="vertical"
            class="bg-grey-lighter"
          />
          <el-tooltip
            :content="$t('actions.refresh')"
            placement="top"
            class="text-right mr-5"
          />
          <span
            class="icon-novo-refresh text-base cursor-pointer hover-icon"
            @click="refresh"
          />
        </div>
      </div>
      <div class="h-full">
        <SmsConversionTable v-loading="loading" :key="nextTickKey" :data="reports.tableData"/>
      </div>
    </div>

    <ExportModal :showDlModal="showExportModal" @close="closeDialog" v-if="false"/>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SmsConversionStats from './partials/SmsConversionStats.vue';
import SmsConversionTable from './partials/SmsConversionTable.vue';
import ExportModal from './partials/ExportModal.vue';
import moment from 'moment';

export default {
  name: 'Reports',
  components: {
    SmsConversionStats,
    SmsConversionTable,
    ExportModal
  },
  data() {
    return {
      // Filters
      filters: {},
      showFilter: true,
      dateFromTo: [moment().subtract(1, 'w').format('YYYY-MM-DD'), (moment().format('YYYY-MM-DD'))],
      useUTC: false,
      showExportModal: false,
      nextTickKey: '',
      country: '',
      operator: '',
      filterCount: 0,
      // End of Filters
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().startOf('day').subtract(31, 'days')
            || time.getTime() > moment();
        },
        shortcuts: [
          {
            text: '1W',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(1, 'w').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '2W',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(2, 'w').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '31D',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(31, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      refreshLoading: false,
      disableApply: true
    };
  },
  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
      getSubAccountDetails: 'verif8/getSubAccountDetails',
      reports: 'verif8/getReports',
      operatorList: 'user/getOperators',
      countryList: 'user/getCountries',
    }),
    reportFilters: `${this.useUTC}|${this.dateFromTo}|${this.country}|${this.operator}`,
  },
  async created() {
    await this.getVerif8Details()
    this.checkFilterCount();
    this.applyFilter();
    this.getOperatorLists(this.filters);
    this.getCountryLists(this.filters);
  },
  methods: {
    ...mapActions({
      getOperatorLists: 'user/getOperatorLists',
      getCountryLists: 'user/getCountryLists',
      getVerif8Details: 'verif8/getVerif8Details',
      getReports: 'verif8/getReports',
    }),
    getSMSConversionStats() {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Converted',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Rejected',
            backgroundColor: '#0B49AA',
            fill: false,
            borderColor: '#0B49AA',
            tension: 0.1,
            data: []
          },
          {
            label: 'Sent',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Converted Rate',
            backgroundColor: '#DD7011',
            fill: false,
            borderColor: '#DD7011',
            tension: 0.1,
            data: []
          }
        ]
      };

      if (this.reports && this.reports.dataByDate) {
        this.reports.dataByDate.forEach((row) => {

          if (this.filters.useUTC) {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(moment.utc(row.date).format('HH:00')) : dataTemplate.labels.push(moment.utc(row.date).format('MM/DD'));
          } else {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(row.date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(row.date).toDateString().replace(/20\d+/, ''));
          }

          if (row.values.length) {
            row.values.forEach((data) => {
              switch(data.label) {
                case 'SmsConverted':
                  dataTemplate.datasets[0].data.push(data.value);
                  break;
                case 'SmsRejected':
                  dataTemplate.datasets[1].data.push(data.value);
                  break;
                case 'SmsCountTotal':
                  dataTemplate.datasets[2].data.push(data.value);
                  break;
                case 'ConversionRate':
                  dataTemplate.datasets[3].data.push(data.value);
                  break;
              }
            });
          } else {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
            dataTemplate.datasets[2].data.push(0);
            dataTemplate.datasets[3].data.push(0);
          }
        });
      }

      return dataTemplate;
    },
    async resetFilters() {
      this.dateFromTo = [moment().subtract(1, 'w').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      this.country = '';
      this.operator = '';
      this.useUTC = false;
      this.checkFilterCount();
      this.applyFilter(true);
    },
    changeTimezone() {
      this.useUTC = !this.useUTC;
      this.applyFilter();
      this.checkFilterCount()
    },
    dateChange(newRange) {
      this.dateFromTo = newRange;
      this.checkFilterCount();
      this.disableApply = false;
    },
    countryChanged(countrySelected) {
      this.country = countrySelected;
      this.operator = '';
      this.checkFilterCount();
      this.disableApply = false;
    },
    operaterChanged(operatorSelected) {
      this.operator = operatorSelected;
      this.checkFilterCount();
      this.disableApply = false;
    },
    async refresh() {
      console.log("Refreshed")
    },
    async applyFilter(shouldUpdate = false) {
      this.filters = {
        dateFromTo: this.dateFromTo,
        useUTC: this.useUTC,
        country: this.country,
        operator: this.operator,
        timeInterval: moment(this.dateFromTo[1]).diff(moment(this.dateFromTo[0]), 'days') <= 1 ? 60 : 1440,
      };

      await this.getReports({
        ...this.filters,
        subAccountUid: this.getSubAccountDetails.SubAccountUid,
      });
      this.nextTickKey = new URLSearchParams(this.filters).toString();
      // await this.getOperatorLists(this.filters);
      if (shouldUpdate) await this.$message.success(this.$t('success.updated', { value: 'Filters' }));

      this.disableApply = true;
    },
    checkFilterCount() {
      this.filterCount = 0;

      if (this.dateFromTo.length > 0) {
        this.filterCount++;
      }
      if (this.useUTC) {
        this.filterCount++;
      }
      if (!!this.country) {
        this.filterCount++;
      }

      if (!!this.operator) {
        this.filterCount++;
      }
    },
    exportLogs() {
      this.showExportModal = true;
    },
    closeDialog() {
      this.showExportModal = false
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
};
</script>

<style scoped lang="scss">
  #verif8-reports {
    .reports-filter-container {
      border: 1px solid #E0E0E0
    }

    .graph-container {
      padding: 1rem;
      box-shadow: 0px 1px 2px rgba(41, 41, 41, 0.25);

      &-header {
        padding-bottom: 1rem;
        justify-content: space-between;
      }

      &-sms-conversion {
        padding: 0 !important;
      }

      .sms-conversions-table-header {
        padding: 16px;
        border-bottom: 1px solid #c2c2c2;
      }
    }
  }
</style>
