<template>
<section id="verif8_getstarted">
  <div class="flex inline-block w-full">
        <h1 class="w-4/5 text-xl text-color font-semibold page-title">
          {{ $t('verif8.title.getstarted') }}
        </h1>
  </div>
  <div class="mb-5 el-main p-0 bg-white h-full px-6 pt-5 mt-2 rounded pb-10">
    <div>
      <h3 class="text-center">{{ $t('verif8.title.step-one-title') }}</h3>
      <div class="px-2 w-full text-center">
        <div class="pt-5">
          <img  
            class="w-1/4"
            src="../../../assets/assets/verif8_try_it.svg"
            alt="Get Started"
          >
          </div>
          <h3 class="mt-3 mb-3">{{ $t('verif8.title.welcome') }}</h3>
          <div class="px-2 py-5 text-sm">
          <p class="pt-2 leading-normal font-sans-['Inter'] text-sm[16px]">
            <i18n
              path="verif8.message.intro"
              tag="p">
              <template #br>
                <br>
              </template>
            </i18n>
          </p>
          </div>
          <div class="px-2 w-full">
            <div class="flex">
              <div class="flex-none w-1/5">&nbsp;</div>
              <div class="w-full border-b-2 py-5">
              <el-button
                type="default"
                @click="goDevDoc()"
                class="w-full text-center"
              >
              <i class="el-icon-open"></i> {{ $t('verif8.message.try_it')}}
              </el-button>
              <br/>
              <p class="text-xs text-left mt-1">{{ $t('verif8.message.verify-page') }} </p>
              </div>
              <div class="flex-none w-1/5">&nbsp;</div>
            </div>
          </div>
          <div class="px-2 w-full">
            <div class="flex">
              <div class="flex-none w-1/5">&nbsp;</div>
              <div class="w-full py-5">
                <h4 class="text-left text-md">{{ $t('verif8.message.details_v8') }}</h4>
                <div class="inline-flex w-full mt-5">
                  <div class="w-1/2 h-20 card text-left">
                    <span class="text-sm pl-10 mt-10">{{ $t('verif8.message.subaccount-id') }} <i class="icon-novo-info-circle"></i></span>
                    <br/><br/>
                    <span class="text-md font-bold pl-10" :key="subAccountDetails.SubAccountId">{{ subAccountDetails.SubAccountId || '' }} </span>
                  </div>
                  <div class="w-1/2 h-20 card ml-2 text-left">
                    <span class="text-sm pl-10 mt-10">{{ $t('verif8.default.sender-id') }} <i class="icon-novo-info-circle"></i></span>
                    <br/><br/>
                    <span class="text-md pl-10 font-bold">{{ $t('verif8.default.verif8') }} </span>
                  </div>
                </div>
                <br/>
                <div class="mt-5 card h-20">
                  <h4 class="text-left text-sm ml-5 mt-2">{{ $t('verif8.message.apikey') }}</h4>
                    <el-input
                      :key="maskedApiKey"
                      :value="maskedApiKey"
                      placeholder="Verif8 (default)"
                      class="w-full relative rounded-md mt-2 mb-2 hover:rounded-md bg-slate-100"
                      size="md"
                    >
                    <span slot="suffix" class="flex items-center">
                      <i 
                        :class="`icon-novo-${showApiKey ? 'eye': 'eye-hide'} cursor-pointer mx-2 my-3 flex items-center`"
                        @click="toggleView()"
                      />
                      <i 
                        class="icon-novo-copy cursor-pointer mx-2 my-3 flex items-center"
                        @click="copyApiKey()"
                      />
                    </span>
                    </el-input>
                </div>
                <p class="leading-normal text-sm">{{ $t('verif8.message.apikey_note') }} </p>
              </div>
              <div class="flex-none w-1/5">&nbsp;</div>
            </div>
          </div>
      </div>
    </div>
  </div>
</section>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'Verif8_GetStarted',
  data() {
    return {
      showApiKey: false,
      maskedApiKey: '*******************************************',
    };
  },
  async mounted() {
    await this.getVerif8Details();
    if (!this.subAccountDetails.SubAccountId && !this.apiKey.ApiKey) {
      this.$router.push({ name: '/verif8' });
      return;
    }
    await this.toggleView();
  },
  created() {},
  computed: {
    ...mapGetters({
      subAccountDetails: 'verif8/getSubAccountDetails',
      apiKey: 'verif8/getApiKey',
    }),
  },
  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
    }),
    goDevDoc() {
      if (!!window) {
        window.open('https://developer.8x8.com/connect/reference/verify-request-v2', '_blank');
      }
    },
    showApiKey() {
      return this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
    },
    toggleView() {
      this.maskedApiKey = this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
      this.showApiKey = !this.showApiKey;
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey.ApiKey);
      this.$message.success('Copied to Clipboard');
    },
  },
};
</script>
<style scoped lang="scss">
  .card {
    padding: 5px;
    background-color: #f1f1f1;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
  }
</style>