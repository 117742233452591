<template>
  <section class="h-full main__section verif8">
    <RouterView />
  </section>
</template>

<script>
export default {
  name: 'layout',
};
</script>
