<template>
  <div v-loading="loading">
    <div class="mt-5 mb-5">
          <h3 class="text-center"> {{ $t('verif8.title.step-one-title') }} </h3>
          <div class="px-2 w-full text-center">
            <div class="pt-5">
              <img  
                class="w-1/4"
                src="../../../assets/assets/verif8_get_started.svg"
                alt="Get Started"
              >
              </div>
              <div class="px-2 py-5 text-sm">
              <p> {{ $t('verif8.title.sub-header') }}</p>
              </div>
              <div class="px-2 w-full">
              </div>
          </div>
          <div class="py-2 px-2 flex w-full">
            <div class="w-1/3">&emsp;</div>
            <div class="shadow-md sm:rounded-lg w-1/3">
                    <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="px-6 py-3">
                                    {{ $t('verif8.table[0]') }}
                                </th>
                                <th>&emsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[1]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[2]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white inline-flex items-center">
                                    {{ $t('verif8.table[3]') }}
                                    <div class="mx-2 px-2 w-2/4 rounded badge-coming items-center flex">
                                      <span>
                                        <svg width="20" height="20" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.7966 3.70885L12.1094 2.87449C12.1581 2.74472 12.3417 2.74472 12.3903 2.87449L12.7032 3.70885C12.7184 3.74941 12.7504 3.78142 12.791 3.79663L13.6254 4.10951C13.7551 4.15818 13.7551 4.34174 13.6254 4.39041L12.791 4.70329C12.7504 4.7185 12.7184 4.75051 12.7032 4.79107L12.3903 5.62543C12.3417 5.7552 12.1581 5.7552 12.1094 5.62543L11.7966 4.79107C11.7814 4.75051 11.7493 4.7185 11.7088 4.70329L10.8744 4.39041C10.7447 4.34174 10.7447 4.15818 10.8744 4.10951L11.7088 3.79663C11.7493 3.78142 11.7814 3.74941 11.7966 3.70885Z" fill="#292929"/>
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.46799 3.24857C7.30577 2.81599 6.69388 2.81599 6.53166 3.24857L5.3069 6.5146C5.2562 6.64982 5.14951 6.7565 5.0143 6.80721L1.74827 8.03197C1.31568 8.19419 1.31568 8.80608 1.74827 8.9683L5.0143 10.1931C5.14951 10.2438 5.2562 10.3505 5.3069 10.4857L6.53166 13.7517C6.69388 14.1843 7.30577 14.1843 7.46799 13.7517L8.69275 10.4857C8.74346 10.3505 8.85014 10.2438 8.98536 10.1931L12.2514 8.9683C12.684 8.80608 12.684 8.19419 12.2514 8.03197L8.98536 6.80721C8.85014 6.7565 8.74346 6.64982 8.69275 6.5146L7.46799 3.24857ZM8.63423 7.74354L10.6518 8.50013L8.63423 9.25673C8.2286 9.40884 7.90854 9.7289 7.75642 10.1345L6.99983 12.1521L6.24323 10.1345C6.09112 9.7289 5.77106 9.40884 5.36542 9.25673L3.34783 8.50013L5.36542 7.74354C5.77106 7.59142 6.09112 7.27136 6.24323 6.86573L6.99983 4.84813L7.75642 6.86573C7.90854 7.27136 8.2286 7.59142 8.63423 7.74354Z" fill="#292929"/>
                                        <path d="M12.1094 11.3745L11.7966 12.2088C11.7814 12.2494 11.7493 12.2814 11.7088 12.2966L10.8744 12.6095C10.7447 12.6582 10.7447 12.8417 10.8744 12.8904L11.7088 13.2033C11.7493 13.2185 11.7814 13.2505 11.7966 13.2911L12.1094 14.1254C12.1581 14.2552 12.3417 14.2552 12.3903 14.1254L12.7032 13.2911C12.7184 13.2505 12.7504 13.2185 12.791 13.2033L13.6254 12.8904C13.7551 12.8417 13.7551 12.6582 13.6254 12.6095L12.791 12.2966C12.7504 12.2814 12.7184 12.2494 12.7032 12.2088L12.3903 11.3745C12.3417 11.2447 12.1581 11.2447 12.1094 11.3745Z" fill="#292929"/>
                                        </svg>
                                      </span>
                                      <span class="text-sm">{{ $t('verif8.badge.coming_soon') }}</span>
                                    </div>
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[4]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[5]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[6]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                            <tr>
                                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    {{ $t('verif8.table[7]') }}
                                </th>
                                <th scope="col" class="px-6 py-3">
                                    <i class="icon-novo-check"></i>
                                </th>
                            </tr>
                        </tbody>
                    </table>
              </div>
              <div class="w-1/3">&emsp;</div>
          </div>
          <div class="w-full flex">
          <div class="w-1/3">&emsp;</div>
          <div class="text-center w-1/3">
            <el-button
                :loading="loading"
                :disabled="loading"
                class="mt-2"
                size="small"
                type="primary"
                @click="nextStep"
              >
              <span v-if="loading"><i class="loading-anim"></i>{{ $t('verif8.loading.subaccount_generate') }} </span>
              <span v-else>{{ $t('verif8.button.continue') }}</span>
            </el-button>
          </div>
          <div class="w-1/3">&emsp;</div>
          </div>
        </div>
  </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Verif8_Step1',
  props: {
    subAccountId: {
      type: String,
      default() {
        return '';
      }
    },
    apiKey: {
      type: String,
      default() {
        return '';
      }
    },
  },

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
    }),
  },

  created() {},

  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
    }),
    nextStep() {
      this.$emit('next-step');
    },
  },
};
</script>

<style scoped lang="scss">
.loading-anim {
  width: 103px;
  color: var(--text);

  &:after {
    content: '';
    display: inline-block;
    animation: ellipsisAnimation steps(1, end) 1s infinite;
  }
}

.badge-coming {
  background-color: #CCDDF9;
}
</style>
