<template>
  <section id="verif8-container">
      <h1 class="text-xl text-color font-semibold page-title">
        {{ $t('verif8.title.setup') }}
      </h1>
      <div class="el-main p-0 bg-white h-full px-6 pt-2 mt-2 rounded">
      
      <template v-if="step === 1">
        <Verif8_Step1 @next-step="nextStep"/>
      </template>
      <template v-if="step==2">
        <Verif8_Step2 
          :sub-account-info="subAccountInfo"
          :api-key="defaultApiKey"
          :key="subAccountInfo.subAccountUid"
          @finish-step="finishStep" 
          @back-step="saveAndExit"
          @generate-key="generateKey"
        />
      </template>
      </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import Verif8_Step1 from './partials/Verif8_Step1.vue';
import Verif8_Step2 from './partials/Verif8_Step2.vue';

export default {
  name: 'Verif8',

  components: {
    Verif8_Step1,
    Verif8_Step2
  },

  data() {
    return {
      step: 0,
      defaultApiKey: '',
      subAccountInfo: {
        subAccountId: '',
        subAccountUid: '',
      }
    };
  },

  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
      subAccountDetails: 'verif8/getSubAccountDetails',
      apiKey: 'verif8/getApiKey',
    }),
  },

  async created() {
    const { Product_VERIF8:  isVerif8Enabled = false } = await this.getUser();
    const user = await this.getUser();
    const verif8 = await this.getVerif8Details();

    if (!verif8.dryRun && verif8.subAccount?.SubAccountId) {
      this.subAccountInfo = {
        subAccountId: verif8.subAccount.SubAccountId,
        subAccountUid: verif8.subAccount.SubAccountUid,
      };
      this.step += 2;
    }

    if (!isVerif8Enabled || !verif8.subAccount?.SubAccountId) {
      this.nextStep();
      this.$router.push({ path: '/' });
      return;
    }

    if (verif8.subAccount?.SubAccountId && verif8.apiKey?.ApiKey && verif8.channels.length) {
      this.$router.push({ path: '/dashboard' });
    }
  },

  methods: {

    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
      createSubAccount: 'verif8/createVerif8SubAccount',
      generateApiKey: 'verif8/generateApiKey',
    }),

    async getUser() {
        let user = {};
        try {
          user = localStorage.getItem('CPV3_User');
          user = JSON.parse(user) || {};
          return user;
        } catch (e) {
          this.$message.error(`Home: Unable to process request at this time.`);
          return;
        }
    },
    async nextStep() {
      switch(this.step) {
        case 0:
          this.step += 1;
          break;
        case 1:
          if (!this.subAccountInfo.subAccountUid) {
            const { success, data } = await this.createSubAccount();
            console.info('Creating SubAccount...');
            if (success) {
              this.subAccountInfo = data;
              this.step += 1;
              break;
            }
          }
          break;
        case 2:
          const { SubAccountId: subAccountId = ''} = this.subAccountDetails;
          const { ApiKey = ''} = this.apiKey;

          this.defaultApiKey = ApiKey

          if (!subAccountId) {
            this.$message.error(`[${this.step}] Can\'t proceed to next step. Error encountered during Verif8 Setup.`);
            break;
          }
          this.$router.push({ path: '/dashboard' });
          break;
        default:
          this.$message.error(`[${this.step}] Can\'t proceed to next step. Error encountered during Verif8 Setup.`);
          break;
      }
      return;
    },
    async finishStep() {
      const user = await this.getUser();
      if (user.BetaFeatures.includes('WEB_Verif8_DryRun')) {
        localStorage.setItem('verif8_dry_run', true);
      }

      if (this.apiKey && this.subAccountDetails) {
        this.$router.push({ path: '/get-started' });
        return;
      }
      this.$message.error(`Can't process request at this time.`);
    },
    saveAndExit() {
      localStorage.setItem('verif8', JSON.stringify({ step: 2, subAccountDetails: this.subAccountDetails, apiKey: this.apiKey }));
      this.$router.push({ path: '/' });
      return;
    },
    generateKey(payload) {
      this.generateApiKey({
        ...payload,
        subAccountUid: this.subAccountInfo.subAccountUid,
      }).then(response => {
        this.defaultApiKey = response?.data?.ApiKey || '';
        this.$message.success(`API Key successfully generated.`);
      }).catch(_ => {
        console.error(_);
        this.$message.error(`Can\'t generate API Key. Error encountered during Verif8 Setup.`);
      });
    }
  },
};
</script>
