import http from '../../../../utils/http';

export default {
  getVerif8Details: async ({ commit }) => {
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data: response } = await http.v2.get('verif8');
      commit({ type: 'SET_VERIF8_SUBACCOUNT', subAccount: response.subAccount });
      commit({ type: 'SET_VERIF8_APIKEY', key: response.apiKey });

      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },
  createVerif8SubAccount: async ({ commit }) => {
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data } = await http.v2.post('verif8/subaccount');
      commit({ type: 'ADD_VERIF8_DETAILS', subAccountDetails: data });
      return data;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  generateApiKey: async ({ commit }, payload) => {
    commit({ type: 'SET_LOADING', loading: true });
    try {
      const { data: response } = await http.v2.post('verif8/api-key', payload);
      commit({ type: 'SET_VERIF8_APIKEY', key: response.data });

      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  getPreviousData: async ({ commit }, params) => {
    commit({ type: 'SET_LOADING', loading: true });

    // Manipulation to retrieve same data from current selected filter
    let newDateFrom = new Date(params.dateTo) - new Date(params.dateFrom);
    newDateFrom = `${new Date(new Date(params.dateFrom) - newDateFrom).toISOString().slice(0, 14)}00`;

    const queryParams = new URLSearchParams({
      dateFrom: newDateFrom,
      dateTo: params.dateFrom,
      timeInterval: params.timeInterval,
      tzOffset: params.tzOffset,
      subAccountUid: params.subAccountUid,
    });

    try {
      const { data: response } = await http.v2.get(`verif8/summary?${queryParams.toString()}`);
      commit({ type: 'SET_PREVIOUS_VERIF8_REPORT', previousData: response });

      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  getCurrentData: async ({ commit }, params) => {
    commit({ type: 'SET_LOADING', loading: true });
    const queryParams = new URLSearchParams({
      dateFrom: params.dateFrom,
      dateTo: params.dateTo,
      timeInterval: params.timeInterval,
      tzOffset: params.tzOffset,
      subAccountUid: params.subAccountUid,
    });
    try {
      const { data: response } = await http.v2.get(`verif8/summary?${queryParams.toString()}`);

      commit({ type: 'SET_CURRENT_VERIF8_REPORT', currentData: response });

      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },

  getReports: async ({ commit }, payload) => {
    commit({ type: 'SET_LOADING', payload: true });
    const searchParams = {};

    if (payload !== null && Object.keys(payload).length > 0) {
      for (const key in payload) {     // eslint-disable-line    
        switch (key) {
        case 'dateFromTo':
          if (payload[key].length === 0) break;
          searchParams.dateFrom = payload[key][0]; // eslint-disable-line
          searchParams.dateTo = payload[key][1]; // eslint-disable-line
          break;
        case 'useUTC':
          searchParams.tzOffset = !payload[key] ? Math.abs(new Date().getTimezoneOffset()) : 0; // eslint-disable-line
          break;
        case 'country':
        case 'operator':
          // Not Used in the API
          break;
        case 'subAccountUid':
          searchParams.subAccountUid = payload[key];
          break;
        default:
          if (typeof payload[key] !== 'undefined') {
            searchParams[key] = payload[key];
          }
          break;
        }
      }
    }

    const newQueryParams = new URLSearchParams(searchParams);

    try {
      const { data: response } = await http.v2.get(`verif8/reports?${newQueryParams.toString()}`);
      const tempTimeInterval = [];
      if (searchParams.timeInterval === 60 && response && response.dataByDate) {
        response.dataByDate.forEach((date) => {
          let idx;
          if (searchParams.tzOffset > 0) {
            idx = new Date(date.date).getHours();
          } else {
            idx = new Date(date.date).getUTCHours();
          }
          tempTimeInterval[idx] = date;
        });
        response.dataByDate = tempTimeInterval;
      }

      commit('SET_REPORT_RESULTS', response);
      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', payload: false });
    }
  },
  getLogs: async ({ commit }, payload) => {
    commit({ type: 'SET_LOADING', loading: true });
    const searchParams = {
      tzOffset: Math.abs(new Date().getTimezoneOffset()),
    };

    if (payload !== null && Object.keys(payload).length > 0) {
      for (const key in payload) {     // eslint-disable-line    
        switch (key) {
        case 'dateRange':
          if (payload[key].length === 0) break;
          searchParams.dateFrom = payload[key][0]; // eslint-disable-line
          searchParams.dateTo = payload[key][1]; // eslint-disable-line
          break;
        case 'subAccountIds':
          searchParams.subAccountIds = payload[key];
          break;
        default:
          if (typeof payload[key] !== 'undefined') {
            searchParams[key] = payload[key];
          }
          break;
        }
      }
    }
    const newQueryParams = new URLSearchParams(searchParams);
    try {
      const { data: response } = await http.v2.get(`verif8/logs?${newQueryParams.toString()}`);
      commit('SET_VERIF8_LOG_DATA', response);
      return response;
    } catch (err) {
      commit('API_ERROR', err, { root: true });
      return Promise.reject(err);
    } finally {
      commit({ type: 'SET_LOADING', loading: false });
    }
  },
};
