
import { Line } from 'vue-chartjs';

export default {
    name: 'SmsConversionStats',
    extends: Line,
    props: {
        chartData: {
            type: Object,
            default () {
                return {
                    labels: [],
                      datasets: [
                        {
                          label: 'Converted',
                          backgroundColor: '#009B89',
                          borderColor: '#009B89',
                          fill: false,
                          tension: 0.1,
                          data: []
                        },
                        {
                          label: 'Rejected',
                          backgroundColor: '#0B49AA',
                          fill: false,
                          borderColor: '#0B49AA',
                          tension: 0.1,
                          data: []
                        },
                        {
                          label: 'Sent',
                          backgroundColor: '#B23683',
                          borderColor: '#B23683',
                          fill: false,
                          tension: 0.1,
                          data: []
                        },
                        {
                          label: 'Converted Rate',
                          backgroundColor: '#DD7011',
                          fill: false,
                          borderColor: '#DD7011',
                          tension: 0.1,
                          data: []
                        }
                    ]
                }
            }
        },
        options: {
            type: Object,
            default () {
                return {
                    responsive: true,
                    maintainAspectRatio: false,
                    stepped: true,
                    legend: {
                        position: 'right'
                    },
                    pointStyle: false,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            data: []
        }
    },
    mounted() {
        this.renderChart(this.chartData, this.options);
    }
}
