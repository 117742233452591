//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters } from 'vuex';
import DestinationCountryChart from './DestinationCountryChart.vue';
import ChannelDeliveryConversionStats from './ChannelDeliveryConversionStats.vue';
import countriesJson from '../../../../src/json/countries.json';
import _ from 'lodash'

export default {
  name: 'Dashboard',
  components: {
    ChannelDeliveryConversionStats,
    DestinationCountryChart,
  },

  data() {
    return {
      showApiKey: false,
      dateRange: '24H',
      maskedApiKey: '',
      delivery: {
        channel: 'sm'
      },
      conversion: {
        channel: 'sm'
      },
      defaultCurrency: 'USD',
      nextTickKey: '',
      filters: {
        channel: 'sm',
        dateFrom: '',
        dateTo: '',
        tzOffset: 0,
        subAccountUid: '',
        timeInterval: 1440, // equivalent to 'day'
      },
      channelFilter: [
        {
          label: 'SMS',
          value: 'sm',
          enabled: true,
        },
        {
          label: 'Viber',
          value: 'vb',
          enabled: false,
        }
      ],
      resultFilters: [
        {
          label: 'Last 24 Hours',
          value: '24H',
        },
        {
          label: 'Last 7 Days',
          value: '7D',
        },
        {
          label: 'Last 31 Days',
          value: '31D',
        },
      ]
    };
  },

  computed: {
    ...mapGetters({
      subAccountDetails: 'verif8/getSubAccountDetails',
      apiKey: 'verif8/getApiKey',
      loading: 'verif8/loading',
      currentData: 'verif8/getCurrentData',
      prevData: 'verif8/getPreviousData',
    }),    
  },

  async created() {
     try {
       await this.getVerif8Details();
       if (!this.subAccountDetails.SubAccountId && !this.apiKey.ApiKey) {
          this.$router.push({ name: '/verif8' });
          return;
       }
       // Get Account Wallet Currency
       const userData = JSON.parse(localStorage['CPV3_User']);
       this.defaultCurrency = userData.Currency;
       this.filters.subAccountUid = this.subAccountDetails.SubAccountUid;
      // Call Reports on default filters
      await this.changeDate('24H' || this.dateRange);
     } catch (_) {
      this.$message.error(`Dashboard: Unable to process request at this time. ${JSON.stringify(_)}`);
     }
  },

  async mounted() {
    if (this.currentData && this.prevData) {
      const { sms } = this.currentData;
    }
  },

  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
      getPreviousData: 'verif8/getPreviousData',
      getCurrentData: 'verif8/getCurrentData',
    }),
    async refresh() {
      this.refreshLoading = true;
      this.changeDeliveryChannel('sm');
      this.changeConversionChannel('sm');
      this.refreshLoading = false;
      this.getDataSummary();
    },
    getDataSummary(attr) {
      const { sms } = this.currentData;
      const value = _.find(sms.summary, { label: attr })?.value || 0;
      return value;
    },
    getPercentValue(oldValue, newValue){
      const percent = Math.ceil(Math.abs((newValue - oldValue)/oldValue * 100));
      if (percent == 'Infinity') {
        return '100%';
      } else if (isNaN(percent)) {
        return '0%';
      } else {
          return `${percent}%`;
      }
    },
    formatValue(currentValue, prevValue, formatToFixed = true) {
      if (currentValue === prevValue || (isNaN(prevValue) || isNaN(currentValue))) {
        return 'No Change';
      } else if (currentValue < prevValue) {
        return formatToFixed ? `-${Math.abs(currentValue - prevValue).toFixed(2)} ` : `-${Math.abs(currentValue - prevValue)}`;
      } else {
        return formatToFixed ? `+${Math.abs(currentValue - prevValue).toFixed(2)}` : `+${Math.abs(currentValue - prevValue)}`;
      }
    },
    async changeDeliveryChannel(channel) {
      this.delivery.channel = channel;
      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    async changeConversionChannel(channel, chartMode) {
      this.conversion.channel = channel;
      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    async changeDate(t) {
      let tempFromDate = new Date();
      this.filters.dateTo = new Date(tempFromDate).toISOString().slice(0, 14) + '00';
      this.filters.timeInterval = 1440; // default
      switch(t) {
        case '7D':
          tempFromDate = new Date().setDate(new Date().getDate() - 7);
          break;
        case '31D':
          tempFromDate = new Date().setDate(new Date().getDate() - 31);
          break;
        case '24H':
        default:
          tempFromDate = new Date().setDate(new Date().getDate() - 1);
          // Override when 24H is selected
          this.filters.timeInterval = 60;
          break;
      }
      this.dateRange = t;
      this.filters.dateFrom = new Date(tempFromDate).toISOString().slice(0, 14) + '00';

      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    generateHexColors() {
      return ['#41B883', '#E46651', '#00D8FF', '#FE2B61', '#424b0f', '#B23683', '#0B49AA', '#E0E0E0', '#009B89', '#D83848', '#FFC693', '#D9991C', '#D2F3E1', '#98CDC2', '#99BBF3', '#246FE5', '#D1B9D8', '#BC3F5F']
    },
    checkTrend(currentData, prevData) {
      if (currentData > prevData) {
        return 'up';
      } else if (currentData < prevData) {
        return 'down';
      } else {
        return 'flat';
      }
    },
    // Dashboard Reports
    getDestinationCountries() {
      const dataTemplate = {
        labels: [],
        datasets: [{
          label: [],
          backgroundColor: this.generateHexColors(),
          data: [],
        }]
      }
      const sortedCountries =  _.sortBy(this.currentData.summary.Countries, obj => Object.values(obj)[0]).reverse();
      sortedCountries.forEach(country => {

        const cName = countriesJson.find(c => c.value === Object.keys(country).shift())?.name || '';
        const countryCount = Object.values(country).shift() || 0;
        dataTemplate.labels.push(cName);
        // For Formatting
        dataTemplate.datasets[0].label.push(`${countryCount}:${cName}:${dataTemplate.datasets[0].backgroundColor[dataTemplate.labels.indexOf(cName)]}` );
        dataTemplate.datasets[0].data.push(countryCount);
      })

      return dataTemplate;
    },
    getChannelConversionStats(channel = 'sm') {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Converted',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Non-converted',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          }
        ]
      }
      if (this.currentData && this.currentData?.dataByDate?.length === 0) {
        return dataTemplate;
      }

      if (this.conversion.channel === 'sm' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(/20\d+/, ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }

          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'SmsConverted') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'SmsCountNonConverted') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      } else if (this.conversion.channel === 'vb' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(new Date().getFullYear(), ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }
          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'ViberCountConverted') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'ViberCountNonConverted') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      }

      return dataTemplate;
    },
    getChannelDeliveryStats(channel = 'sm') {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Delivered',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Undelivered',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          }
        ]
      }

      if (this.delivery.channel === 'sm' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(/20\d+/, ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }

          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'SmsCountDelivered') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'SmsCountUndelivered') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      } else if (this.delivery.channel === 'vb' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(new Date().getFullYear(), ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }
          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'ViberCountDelivered') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'ViberCountDelivered') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      }

      return dataTemplate;
    },
  },
};
