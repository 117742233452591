<template>
    <div class="bg-white w-full rounded relative sms-conversion-table">
        <el-table
        class="w-full"
        :data="getPaginatedData"
        fit
        scrollable>
            <el-table-column scope="col" label="Date" sortable :sort-method="sortDate" header-align="left" align="left">
                <template slot-scope="scope">
                    <span class="text-xs">{{ new Date(scope.row.TimeFrom).toDateString().replace(new Date().getFullYear(), '') }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Sent" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Sent')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsDelivered || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Chargeable" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Chargeable')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsChargeable || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Delivered" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Delivered')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsDelivered || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Undelivered" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Undelivered')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsUndelivered || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Rejected" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Rejected')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsRejected || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Converted" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Converted')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.SmsConverted || 0 }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="left" align="left" label="Conversion Rate" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'ConversionRate')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ `${Number(scope.row.ConversionRate).toFixed(2) || '0.00'}%` }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="right" align="left" label="Currency" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'Currency')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ scope.row.Currency || 'USD' }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="right" align="right" label="Delivery Cost" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'DeliveryCost')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ `${scope.row.DeliveryCost && scope.row.DeliveryCost.toFixed(2) || '0.00'}` }}</span>
                </template>
            </el-table-column>
            <el-table-column header-align="right" align="right" label="Conversion Cost" scope="col" sortable :sort-method="(a, b) => genericSort(a ,b, 'ConversionCost')">
                <template slot-scope="scope">
                    <span class="text-xs">{{ `${scope.row.ConversionCost && scope.row.ConversionCost.toFixed(2) || '0.00'}` }}</span>
                </template>
            </el-table-column>
        </el-table>
        <!-- Pagination -->
      <div class="my-2 mx-3 text-right">
        <div class="bg-white flex justify-between items-center text-xs leading-none">
          <div class="text-base">
            <span> {{ $t("app_labels.rows_per_page") }}:</span>
            <el-select
              v-model="pageSize"
              class="ml-3"
              style="width: 65px"
              @change="handleSizeChange"
            >
              <el-option
                v-for="page in [10,20,50]"
                :key="page"
                :label="page"
                :value="page"
              />
            </el-select>
          </div>
          <div class="flex items-end">
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base"
              :class="{ 'cursor-not-allowed text-grey disabled disable-events': loading || pageNo === 1}"
              @click.prevent="handlePaginationChange('prev')"
            >
              <i class="icon-novo-arrow-left-long mr-2" /> <span class="capitalize">{{ $t('actions.prev') }}</span>
            </a>
            <div class="text-base mr-3 ml-3">
              <el-select
                v-model="pageNo"
                style="width: 65px"
                @change="handleSelectPage"
              >
                <el-option
                  v-for="page in lastPage"
                  :key="page"
                  :label="page"
                  :value="page"
                />
              </el-select>
              {{ $t("app_labels.of_item_pages", { item: lastPage.toLocaleString() }) }}
            </div>
            <a
              class="p-3 inline-block leading-none cursor-pointer text-base capitalize"
              :class="{ 'cursor-not-allowed text-grey disabled disable-events': loading || pageNo >= lastPage }"
              @click.prevent="handlePaginationChange('next')"
            >
              <span class="capitalize">{{ $t('actions.next') }}</span> <i class="ml-2 icon-novo-arrow-right-long" />
            </a>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

export default {
    name: 'SmsConversionTable',
    props: {
        data: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 10,
            pageNo: 1,
            total: 1
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.data.length / parseInt(this.pageSize, 10));
        },

        getPaginatedData() {
            const start = (this.pageNo - 1) * this.pageSize;
            return this.data.slice(start, start + this.pageSize);
        },
    },
    methods: {
        handleSizeChange(size) {
            this.pageSize = Number(size)
        },
        handleSelectPage(pn) {
            this.pageNo = Number(pn);
        },
        handlePaginationChange(action) {
            let page = this.pageNo;
            if (action === 'next') {
                page += 1;
            }

            if (action === 'prev' && page) {
                page -= 1;
            }

            this.pageNo = Number(page);
        },
        setPage(page) {
            this.pageNo = page;
        },
        genericSort(a, b, column) {
            return a[column] - b[column];
        },
        sortDate(a, b) {
            return new Date(a.TimeFrom) - new Date(b.TimeFrom);
        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="scss">
.sms-conversion-table {
    ::v-deep .el-table th>.cell {
        display: flex;
        align-items: center;
        word-break: unset;
        justify-content: space-between;
        text-align: left;
    }
}
</style>