<template>
  <section id="verif8-logs">
    <div class="flex inline-block w-full mb-5">
      <h1 class="w-4/5 text-xl text-color font-semibold page-title">
        <!-- {{ $t('verif8.title.logs') }} -->
        Verif8 · Logs <span class="text-md"><i class="icon-novo-info-circle"></i></span>
      </h1>
      <div class="w-1/5 text-right">
        <el-button 
            size="sm"
            type="primary"
            @click="exportLogs"
            v-if="false"
        >
            <span class="text-md text-right" v-if="false"><i class="icon-novo-download"></i> Export</span>
        </el-button>
      </div>
    </div>
    <div class="bg-white log-container rounded">
      <div class="mb-5 el-main p-0 bg-white h-auto pt-3 mt-2 rounded">
        <div class="flex items-center pb-2" style="border-bottom: 1px solid #e0e0e0;">
          <div class="w-4/5 pl-2">
            <el-input
              placeholder="Search"
              prefix-icon="icon-novo-search"
              v-model="searchText"
            />
          </div>
          <div class="w-1/5">
            <el-button 
              size="sm"
              class="inline-flex items-center ml-3"
              style="background-color:#E0E0E0;border-color:#E0E0E0"
              @click="showHideFilter"
            >
              <span class="text-md text-right" style="color:#292929"><i class="icon-novo-filter"></i></span>
              <span class="absolute -mt-4 px-2 py-1 text-xs text-black rounded-full" style="background-color:#F8AE1A" v-if="filterCount !== 0">
                {{ filterCount }}
              </span>
            </el-button>
            <span :key="dataCount">
              {{ dataCount }} results
            </span>
            <el-divider
              direction="vertical"
              class="bg-grey-lighter"
            />
              <el-tooltip
                :content="$t('actions.refresh')"
                placement="top"
                class="text-right"
              >
                <span
                  class="icon-novo-refresh text-base cursor-pointer hover-icon"
                  @click="refresh"
                />
              </el-tooltip>
            </div>
          </div>
        </div>
        <div class="flex items-center pb-2" v-if="showFilter" :key="showFilter" style="border-bottom: 1px solid #e0e0e0;">
            <div class="w-4/5">
              <el-button 
                v-if="!isPickerClicked"
                size="small"
                class="inline-flex items-center ml-3"
                style="background-color:#CCDDF9;border-color:#CCDDF9;color: #292929"
                @click="() => isPickerClicked = !isPickerClicked"
              >
              Date Range is {{ dateRangeText }} <icon class="el-icon-caret-bottom"></icon>
              </el-button>
                <el-date-picker
                  v-if="isPickerClicked"
                  :focus="isPickerClicked"
                  type="daterange"
                  format="dd MMM yyyy"
                  value-format="yyyy-MM-dd"
                  start-placeholder="Date Start"
                  end-placeholder="Date End"
                  :clearable="false"
                  :editable="false"
                  align="left"
                  size="small"
                  class="ml-3"
                  style="background-color:#CCDDF9;border-color:#CCDDF9;color: #292929"
                  :picker-options="pickerOptions"
                  v-model="appliedFilters.dateRange"
                  @change="dateChange"
                  @pick="dateChange"
                >
                </el-date-picker>
                <el-select 
                class="ml-3 mb-3" 
                placeholder="Sub-account" 
                :disabled="true"
                suffix-icon="el-icon-close"
                size="small"
                v-if="filterOptions.find(opt => opt.value === 'subAccountIds').added" 
                v-model="appliedFilters.subAccountIds">
                  <el-option
                    v-for="subVal in filterOptions.find(opt => opt.value === 'subAccountIds').possibleValue"
                    :key="subVal.key"
                    :label="subVal.name"
                    :value="subVal.value">
                  </el-option>
                </el-select>
                <el-select size="small"
                v-for="option in filterOptions"
                suffix-icon="el-icon-close"
                :placeholder="option.name"
                class="ml-3 mb-3"
                :filterable="option.value === 'umid'"
                :filter-method="filterMethod"
                :key="filterOptions"
                v-model="appliedFilters[option.value]"
                :disabled="loading"
                @change="val => handleFilterChange(val, option.value)"
                v-if="showEnabledFilter(option)">
                  <el-option v-for="subVal in option.possibleValue"
                  :key="subVal.key"
                  :label="subVal.name"
                  :value="subVal.value">
                  </el-option>
                <el-button class="mt-3 ml-3" type="danger" size="small" plain @click="removeFilter(option.value)">Remove</el-button>
                </el-select>
                <!-- MSISDN -->
                <el-input 
                  v-if="filterOptions.find(opt => opt.value === 'msisdn').added" 
                  class="mx-3 w-1/5"
                  size="small"
                  v-model="tempFilters.msisdn"
                  placeholder="Destination (MSISDN)"
                >
                </el-input>
                <el-select 
                size="small"
                placeholder="Select Filter"
                :show-arrow="false"
                @change="enableFilter"
                v-if="showAddFilter"
                >
                  <el-option v-for="option in filterOptions"
                  :key="option.value"
                  :label="option.name"
                  :disabled="option.added"
                  :value="option.value">
                  </el-option>
                </el-select>
                <a href="javascript:void(0)" :class="`no-underline text-sm text-blue-light pt-2 ${disableAddFilter ? 'disabled': ''}`" @click="chooseAddFilter"><i class="icon-novo-plus"></i> Add Filter</a>
            </div>
            <div class="ml-auto 1/5 text-right flex inline-flex pr-5 items-center">
                <a href="javascript:void(0)" class="no-underline text-sm text-blue-light" @click="resetFilters" >Reset</a>
                <el-button type="primary" size="small" class="ml-3" @click="applyFilter(true)">
                    Apply 
                </el-button>
            </div>
        </div>
        <LogsTable :data="getLogData()" v-loading="loading" :key="logData.length"/>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LogsTable from './LogsTable.vue';
import countriesJson from '../../../../src/json/countries.json'
import moment from 'moment';
import _ from 'lodash';

export default {
    name: 'Logs',

    components: {
      LogsTable
    },

    data() {
        return {
            showAddFilter: false,
            showFilter: true,
            appliedFilters: {},
            defaultFilters: {
              subAccountIds: '',
              dateRange: [moment().subtract(1, 'w').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
            },
            tempFilters: {},
            searchText: '',
            tempData: [],
            filterCount: 0,
            isPickerClicked: false,
            dateRangeText: '',
            dataCount: 0,
            disableAddFilter: false,
            filterOptions: [
              { name: 'Date Range (UTC-00)', added: true, value: 'dateRange', possibleValue: [] },
              { name: 'Sub-account', added: true, value: 'subAccountIds', possibleValue: [] },
              { name: 'Destination (MSISDN)', added: false, value: 'msisdn', possibleValue: '' },
              { name: 'Channel', added: false, value: 'channel', possibleValue: [] },
              //{ name: 'Direction', added: false, value: 'direction', possibleValue: [] },
              { name: 'Status', added: false, value: 'status', possibleValue: [] },
              { name: 'Country', added: false, value: 'country', possibleValue: [] },
              { name: 'Operator', added: false, value: 'operator', possibleValue: [] },
              { name: 'Message ID (UMID)', added: false, value: 'umid', possibleValue: [] },
            ],
            pickerOptions: {
              disabledDate(time) {
                return time.getTime() < moment().startOf('day').subtract(31, 'days')
                    || time.getTime() > moment();
              },
              shortcuts: [
                {
                  text: '1W',
                  onClick(picker) {
                    const end = moment().format('YYYY-MM-DD');
                    const start = moment().subtract(1, 'w').format('YYYY-MM-DD');
                    picker.$emit('pick', [start, end]);
                  },
                },
                {
                  text: '2W',
                  onClick(picker) {
                    const end = moment().format('YYYY-MM-DD');
                    const start = moment().subtract(2, 'w').format('YYYY-MM-DD');
                    picker.$emit('pick', [start, end]);
                  },
                },
                {
                  text: '31D',
                  onClick(picker) {
                    const end = moment().format('YYYY-MM-DD');
                    const start = moment().subtract(31, 'days').format('YYYY-MM-DD');
                    picker.$emit('pick', [start, end]);
                  },
                },
              ],
            },
        };
    },
    computed: {
      ...mapGetters({
        loading: 'verif8/loading',
        logData: 'verif8/getLogData',
        operatorList: 'user/getOperators',
        subAccountDetails: 'verif8/getSubAccountDetails',
      })      
    },
    async created() {
      await this.getVerif8Details();
      this.defaultFilters.subAccountIds = this.subAccountDetails?.SubAccountId;
      this.tempFilters = _.cloneDeep(this.defaultFilters);
      await this.applyFilter();
      await this.getOperatorLists(this.appliedFilters);
      this.dataCount = this.logData.length;
      this.showFilter = true;
    },
    methods: {
      ...mapActions({
        getOperatorLists: 'user/getOperatorLists',
        getVerif8Details: 'verif8/getVerif8Details',
        getLogs: 'verif8/getLogs',
        // exportLogs: 'verif8/exportLogs',
      }),
      enableFilter(sel) {
        this.showAddFilter = false;
        this.filterOptions.forEach((option) => {
          if (option.value === sel) {
            option.added = true;
          }
        });
        if (!(this.filterOptions.filter(opt => !opt.added).length)) {
          this.disableAddFilter = true;
        }
        this.populateFilterValues();
      },
      getLogData() {
        return !this.searchText ? this.logData : this.tempData;
      },
      showEnabledFilter(opt) {        
        return opt.added && opt.value !== 'dateRange' && opt.value !== 'subAccountIds' && opt.value !== 'msisdn';
      },
      removeFilter(filter) {
        this.filterOptions.forEach(option => {
          if (option.value === filter) {
            option.added = false;
            this.appliedFilters[filter] = '';
            this.tempFilters[filter] = '';
          }
        });
        this.populateFilterValues();
      },
      getLogData() {
        return !this.searchText ? this.logData : this.tempData;
      },
      showEnabledFilter(opt) {        
        return opt.added && opt.value !== 'dateRange' && opt.value !== 'subAccountIds' && opt.value !== 'msisdn';
      },
      removeFilter(filter) {
        this.filterOptions.forEach(option => {
          if (option.value === filter) {
            option.added = false;
            this.appliedFilters[filter] = '';
          }
        });
      },
      populateFilterValues() {
        if (this.logData.length > 0) {
          this.filterOptions.forEach((option) => {
            switch(option.value) {
              case 'subAccountIds':
                option.possibleValue = this.subAccountDetails?.SubAccountId;
                break;
              case 'channel':
                this.logData.map(log => {
                    if (option.possibleValue.findIndex(val => val.value === log.ChannelType) === -1) {
                      return option.possibleValue.push({ name: log.ChannelType, value: log.ChannelType });
                    } 
                })
                break;
              case 'country':
                option.possibleValue = [];
                this.logData.map(log => {
                    if (option.possibleValue.findIndex(val => val.value === log.Country) === -1) {
                      const name = countriesJson.find(cty => cty.value === log.Country).name;
                      return option.possibleValue.push({ name, value: log.Country });
                    } 
                })
                break;
              case 'status':
                this.logData.map(log => {
                    if (option.possibleValue.findIndex(val => val.value === log.StatusId) === -1) {
                      return option.possibleValue.push({ name: log.Status, value: log.StatusId });
                    }
                })
                break;
              case 'operator':
                option.possibleValue = [];
                if (this.tempFilters.country) {
                  this.operatorList.map(op => {
                    if (op.Country === this.tempFilters.country) {
                      return option.possibleValue.push({ name: op.OperatorName, value: op.OperatorId });
                    }
                  });
                } else {
                  this.operatorList.map(op => {
                    if (option.possibleValue.findIndex(val => val.value === op.OperatorId) === -1) {
                      return option.possibleValue.push({ name: op.OperatorName, value: op.OperatorId });
                    }
                  });
                }
                break;
              case 'destination':
              case 'dateRange':
              default:
                break;
            }
          });
        }
      },
      filterMethod(searchValue) {
        // Direct assignment to reflect values on dropdown
        this.appliedFilters.umid = searchValue;

        let foundUmids = this.logData.filter(log => log.UMID.includes(searchValue))?.map(log => {
          return { name: log.UMID, value: log.UMID };
        });
        this.filterOptions[this.filterOptions.findIndex(opt => opt.value === 'umid')].possibleValue = foundUmids;

        if (searchValue.length === 36) {
          this.tempFilters['umid'] = searchValue;
          this.applyFilter();
        }
      },
      chooseAddFilter() {
        const hasAvailable = this.filterOptions.filter(option => !option.added)
        if (hasAvailable && hasAvailable.length > 0) {
          this.showAddFilter = true;
        }
      },
      handleFilterChange(val, key) {
        if (key === 'umid') {
          this.appliedFilters[key] = val;
        }
        if (key === 'country') {
          this.appliedFilters['operator'] = '';
        }
        if (val === '-1') {
          this.filterOptions.forEach(option => {
            if (option.value === key) {
              option.added = false;
            }
          });
          this.tempFilters[key] = '';
          this.applyFilter();
        }
        this.tempFilters[key] = val;
        this.applyFilter();
      },
      async refresh() {
        this.applyFilter();
      },
      async search() {
        this.tempData = [];
        const searchText = this.searchText.toLowerCase();

        this.logData.forEach((log) => {
          switch (searchText) {
            case 'converted':
              if (log.PriceOTP) {
                this.tempData.push(log);
              }
              break;
            case 'non-converted':
              if (!log.PriceOTP) {
                this.tempData.push(log);
              }
              break;
            default:
                if (log.Status.indexOf(searchText) !== -1 || log.SubAccountId.indexOf(searchText) !== -1 || log.MSISDN.indexOf(searchText) !== -1 || log.ChannelType.indexOf(searchText) !== -1) {
                  this.tempData.push(log);
                }
              break;
          }
        });
      },
      checkFilterCount() {
        this.filterCount = 0;
        const values = Object.values(this.appliedFilters);

        this.filterCount = values.length;
      },
      async resetFilters(e) {
        this.tempFilters = _.cloneDeep(this.defaultFilters);
        this.applyFilter();
        this.disableAddFilter = false;
        this.tempData = [];
        this.filterOptions.forEach(option => {
          if (option.value === 'dateRange' || option.value === 'subAccountIds') {
            option.added = true;
          } else {
            option.added = false;
          }
        });
        this.$message.warning(this.$t('success.changed', { value: 'Log Filters' }));
      },
      async applyFilter(shouldUpdate = false) {
        this.appliedFilters = _.cloneDeep(this.tempFilters);
        this.dateRangeText = `${moment(this.appliedFilters.dateRange[0]).format('D MMM YYYY')} to ${moment(this.appliedFilters.dateRange[1]).format('D MMM YYYY')}`;
        this.checkFilterCount();
        await this.getLogs(this.appliedFilters);
        this.dataCount = this.logData.length;
        this.search();
        await this.getOperatorLists(this.appliedFilters);
        this.populateFilterValues();
        if (shouldUpdate) await this.$message.success(this.$t('success.updated', { value: 'Log Filters' }));
      },
      dateChange(e) {
        this.tempFilters.dateRange = e;
        this.dateRangeText = `${moment(e[0]).format('D MMM YYYY')} to ${moment(e[1]).format('D MMM YYYY')}`;
        this.isPickerClicked = false;
        this.populateFilterValues();
      },
      showHideFilter() {
        this.showFilter = !this.showFilter;
        console.log(this.showFilter);
      }
    }
}
</script>

<style scoped style="scss">
a.disabled {
  pointer-events: none;
  cursor: default;
  color: #ccc;
  text-decoration: none;
}

.log-container {
  border: 1px solid #e0e0e0;
}
</style>