//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Verif8_Step1',
  props: {
    subAccountId: {
      type: String,
      default() {
        return '';
      }
    },
    apiKey: {
      type: String,
      default() {
        return '';
      }
    },
  },

  data() {
    return {
      
    };
  },

  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
    }),
  },

  created() {},

  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
    }),
    nextStep() {
      this.$emit('next-step');
    },
  },
};
