<template>
<div class="">
  <div class="flex flex-col mb-2" style="height:70dvh;">
    <div class="w-1/4">
      <h4 class="pt-5 mt-5 ml-20 text-left">{{ $t('verif8.title.account-details') }}</h4>
    </div>

    <div class="ml-20 inline-flex columns-2 mt-5">
        <div class="h-16 w-1/3 border-r-2 sa-bg pl-5 pt-5 pb-3 rounded-s-lg">
          <span class="text-left">{{ $t('verif8.title.sub-account-id') }} <i class="icon-novo-info-circle"></i></span>
        </div>
        <div class="w-1/3 pl-10 sa-bg pt-6 rounded-e-lg">
          {{ subAccountInfo.subAccountId || subAccountInfo.SubAccountId|| 'AccountId_Verif8' }}
        </div>
      </div>
      <div class="ml-20 inline-flex columns-2 mt-5">
        <div class="h-16 border-r-2 w-1/3 pl-5 sa-bg pt-6 rounded-s-lg">
          <span class="text-left">{{ $t('verif8.title.sender-id') }} <i class="icon-novo-info-circle"></i></span>
        </div>
        <div class="w-1/3 pl-10 sa-bg pt-6 rounded-e-lg">
          <span v-if="senderId='Verif8'">{{senderId}}&nbsp;<sub class="text-xs font-gray-400">(default)</sub></span>
          <span v-else>{{ senderId }}</span>
        </div>
    </div>

    <div class="w-full">
      <div class="flex" v-if="!!apiKey">
        <div class="flex-none w-1/6">&nbsp;</div>
        <div class="w-full h-48 pt-5 mt-5 ml-20 text-left text-center items-center">
          <h4>{{ $t('verif8.message.apikey_created') }} 🎉</h4>
          <p class="pt-5 text-sm">{{ $t('verif8.message.details') }}</p>
          <div class="api-bg-success mt-5 pt-5 h-20 text-left ">
            <label class="text-xs font-bold">{{ $t('verif8.message.apikey') }} </label>
            <br/>
            <div class="inline-block flex mt-2">
              <el-input
                  :key="maskedApiKey"
                  :value="maskedApiKey"
                  placeholder="Verif8 (default)"
                  class="w-full rounded hover:rounded-md bg-slate-100"
                  size="small"  
              >
                <span slot="suffix" class="flex items-center">
                  <i 
                    :class="`icon-novo-${showApiKey ? 'eye': 'eye-hide'} cursor-pointer mx-2 my-3 flex items-center`"
                    @click="toggleView()"
                  />
                  <i 
                    class="icon-novo-copy cursor-pointer mx-2 my-3 flex items-center"
                    @click="copyApiKey()"
                  />
                </span>
              </el-input>
            </div>
            <p class="text-xs mt-2">{{ $t('verif8.message.unique_key') }}</p>
          </div>
        </div>
        <div class="flex-none w-1/6">&nbsp;</div>
      </div>
      <div class="h-48 pt-5 mt-5 ml-20 text-left border-t-2 items-center" v-else>
        <h4>{{ $t('verif8.message.generate') }}</h4>
        <p class="pt-5 text-sm"> {{ $t('verif8.message.apikey_access_p1') }}
        <br/>{{ $t('verif8.message.apikey_access_p2') }}</p>
        <div class="api-bg mt-5 pt-5 h-20 w-1/2 text-left" >
          <label class="text-xs">{{ $t('verif8.label.name') }}</label>
          <br/>
          <div class="inline-block flex" v-loading="loading">
            <el-input
                v-model="apiKeyName"
                placeholder="Verif8 (default)"
                class="w-1/2 rounded hover:rounded-md"
                size="small"
                clearable
            />
            <el-button 
                :loading="false"
                :disabled="false"
                size="small"
                class="ml-2"
                type="primary"
                @click="generateKey">
                {{ $t('verif8.button.generate') }}
            </el-button>
          </div>
          <p class="text-xs mt-2">You can identify your generated key in the API Keys table.</p>
        </div>
      </div>
    </div>
  </div>
    <div class="w-full inline-block h-24 text-right pt-10 border-t-2">
      <el-button 
          v-if="!apiKey"
          :loading="false"
          :disabled="false"
          class="mt-2"
          size="small"
          type="default"
          @click="backStep">
          {{ $t('verif8.button.save_and_exit') }}
      </el-button>
      <el-button 
          :loading="false"
          :disabled="false"
          class="mt-2"
          size="small"
          type="primary"
          @click="nextStep">
          {{ $t('verif8.button.continue_with_v8') }}
      </el-button>
    </div>
</div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'Verif8_Step2',
  props: {
    loading: {
      type: Boolean,
      default() {
        return false;
      }
    },
    subAccountInfo: {
      type: Object,
      default() {
        return {
          subAccountId: '',
          subAccountUid: '',
        };
      }
    },
    senderId: {
      type: String,
      default() {
        return 'Verif8';
      }
    },
    senderId: {
      type: String,
      default() {
        return 'Verif8';
      }
    }
  },

  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
      apiKey: 'verif8/getApiKey',
    }),
  },

  data() {
    return {
      apiKeyName: 'Verif8_Default',
      showApiKey: false,
      maskedApiKey: '*******************************************',
    };
  },


  async mounted() {
    const { data } = await this.getAuthUser();
    
    if (data) {
      const event = new Event('verif8:success-onboarding');
      window.dispatchEvent(event);
      localStorage.setItem('CPV3_User', JSON.stringify(data));
    }
  },

  methods: {
    ...mapActions({
      getAuthUser: 'user/getAuthUser',
    }),
    nextStep() {
      this.$emit('finish-step');
    },
    backStep() {
      this.$emit('back-step');
    },
    generateKey() {
      this.$emit('generate-key', { name: this.apiKeyName });
    },
    toggleView() {
      this.maskedApiKey = this.showApiKey ? this.apiKey.ApiKey : this.apiKey.ApiKey.replace(/./g, '*');
      this.showApiKey = !this.showApiKey;
      console.log(this.maskedApiKey);
    },
    copyApiKey() {
      navigator.clipboard.writeText(this.apiKey.ApiKey);
      this.$message.success('Copied to Clipboard');
    },
  },
};
</script>

<style scoped lang="scss">
  .api-bg {
    padding: 10px;
    background-color: #f1f1f1;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
  }

  .api-bg-success {
    padding: 10px;
    background-color: #CCDDF9;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
  }
  .sa-bg {
    background-color: #f5f5f5;
  }
  .sid-bg {
    background-color: #f5f5f5;
  }
  .rounded-s-lg {
    border-start-start-radius: 0.5rem; /* 8px */
    border-end-start-radius: 0.5rem; /* 8px */
  }
  .rounded-e-lg {
    border-start-end-radius: 0.5rem; /* 8px */
    border-end-end-radius: 0.5rem; /* 8px */
  }
</style>
