//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import SmsConversionStats from './partials/SmsConversionStats.vue';
import SmsConversionTable from './partials/SmsConversionTable.vue';
import ExportModal from './partials/ExportModal.vue';
import moment from 'moment';

export default {
  name: 'Reports',
  components: {
    SmsConversionStats,
    SmsConversionTable,
    ExportModal
  },
  data() {
    return {
      // Filters
      filters: {},
      showFilter: true,
      dateFromTo: [moment().subtract(1, 'w').format('YYYY-MM-DD'), (moment().format('YYYY-MM-DD'))],
      useUTC: false,
      showExportModal: false,
      nextTickKey: '',
      country: '',
      operator: '',
      filterCount: 0,
      // End of Filters
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < moment().startOf('day').subtract(31, 'days')
            || time.getTime() > moment();
        },
        shortcuts: [
          {
            text: '1W',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(1, 'w').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '2W',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(2, 'w').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
          {
            text: '31D',
            onClick(picker) {
              const end = moment().format('YYYY-MM-DD');
              const start = moment().subtract(31, 'days').format('YYYY-MM-DD');
              picker.$emit('pick', [start, end]);
            },
          },
        ],
      },
      refreshLoading: false,
      disableApply: true
    };
  },
  computed: {
    ...mapGetters({
      loading: 'verif8/loading',
      getSubAccountDetails: 'verif8/getSubAccountDetails',
      reports: 'verif8/getReports',
      operatorList: 'user/getOperators',
      countryList: 'user/getCountries',
    }),
    reportFilters: `${this.useUTC}|${this.dateFromTo}|${this.country}|${this.operator}`,
  },
  async created() {
    await this.getVerif8Details()
    this.checkFilterCount();
    this.applyFilter();
    this.getOperatorLists(this.filters);
    this.getCountryLists(this.filters);
  },
  methods: {
    ...mapActions({
      getOperatorLists: 'user/getOperatorLists',
      getCountryLists: 'user/getCountryLists',
      getVerif8Details: 'verif8/getVerif8Details',
      getReports: 'verif8/getReports',
    }),
    getSMSConversionStats() {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Converted',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Rejected',
            backgroundColor: '#0B49AA',
            fill: false,
            borderColor: '#0B49AA',
            tension: 0.1,
            data: []
          },
          {
            label: 'Sent',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Converted Rate',
            backgroundColor: '#DD7011',
            fill: false,
            borderColor: '#DD7011',
            tension: 0.1,
            data: []
          }
        ]
      };

      if (this.reports && this.reports.dataByDate) {
        this.reports.dataByDate.forEach((row) => {

          if (this.filters.useUTC) {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(moment.utc(row.date).format('HH:00')) : dataTemplate.labels.push(moment.utc(row.date).format('MM/DD'));
          } else {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(row.date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(row.date).toDateString().replace(/20\d+/, ''));
          }

          if (row.values.length) {
            row.values.forEach((data) => {
              switch(data.label) {
                case 'SmsConverted':
                  dataTemplate.datasets[0].data.push(data.value);
                  break;
                case 'SmsRejected':
                  dataTemplate.datasets[1].data.push(data.value);
                  break;
                case 'SmsCountTotal':
                  dataTemplate.datasets[2].data.push(data.value);
                  break;
                case 'ConversionRate':
                  dataTemplate.datasets[3].data.push(data.value);
                  break;
              }
            });
          } else {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
            dataTemplate.datasets[2].data.push(0);
            dataTemplate.datasets[3].data.push(0);
          }
        });
      }

      return dataTemplate;
    },
    async resetFilters() {
      this.dateFromTo = [moment().subtract(1, 'w').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')];
      this.country = '';
      this.operator = '';
      this.useUTC = false;
      this.checkFilterCount();
      this.applyFilter(true);
    },
    changeTimezone() {
      this.useUTC = !this.useUTC;
      this.applyFilter();
      this.checkFilterCount()
    },
    dateChange(newRange) {
      this.dateFromTo = newRange;
      this.checkFilterCount();
      this.disableApply = false;
    },
    countryChanged(countrySelected) {
      this.country = countrySelected;
      this.operator = '';
      this.checkFilterCount();
      this.disableApply = false;
    },
    operaterChanged(operatorSelected) {
      this.operator = operatorSelected;
      this.checkFilterCount();
      this.disableApply = false;
    },
    async refresh() {
      console.log("Refreshed")
    },
    async applyFilter(shouldUpdate = false) {
      this.filters = {
        dateFromTo: this.dateFromTo,
        useUTC: this.useUTC,
        country: this.country,
        operator: this.operator,
        timeInterval: moment(this.dateFromTo[1]).diff(moment(this.dateFromTo[0]), 'days') <= 1 ? 60 : 1440,
      };

      await this.getReports({
        ...this.filters,
        subAccountUid: this.getSubAccountDetails.SubAccountUid,
      });
      this.nextTickKey = new URLSearchParams(this.filters).toString();
      // await this.getOperatorLists(this.filters);
      if (shouldUpdate) await this.$message.success(this.$t('success.updated', { value: 'Filters' }));

      this.disableApply = true;
    },
    checkFilterCount() {
      this.filterCount = 0;

      if (this.dateFromTo.length > 0) {
        this.filterCount++;
      }
      if (this.useUTC) {
        this.filterCount++;
      }
      if (!!this.country) {
        this.filterCount++;
      }

      if (!!this.operator) {
        this.filterCount++;
      }
    },
    exportLogs() {
      this.showExportModal = true;
    },
    closeDialog() {
      this.showExportModal = false
    },
    showHideFilter() {
      this.showFilter = !this.showFilter;
    },
  },
};
