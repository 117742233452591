import Layout from './views/Layout.vue';
import Home from './views/home/Home.vue';
import Dashboard from './views/dashboard/Dashboard.vue';
import Reports from './views/reports/Reports.vue';
import Logs from './views/logs/Logs.vue';
import GetStarted from './views/home/partials/Verif8_GetStarted.vue';

export default [
  {
    path: '/',
    name: 'layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'home',
        component: Home,
      },
      {
        path: '/dashboard',
        name: 'verif8-dashboard',
        component: Dashboard,
      },
      {
        path: '/get-started',
        name: 'verif8-get-started',
        component: GetStarted,
      },
      {
        path: '/reports',
        name: 'verif8-reports',
        component: Reports,
      },
      {
        path: '/logs',
        name: 'verif8-logs',
        component: Logs,
      },
      // {
      //   path: '/templates',
      //   name: 'verif8-templates',
      //   component: Templates,
      // },
    ],
  },
];
