
import { Doughnut, mixins } from 'vue-chartjs';


export default {
  name: 'DestinationCountryChart',
  extends: Doughnut,
  mixins: [ mixins.reactiveProp ],
  props: {
    chartData: {
      type: Object,
      default() {
        return {
            labels: [],
            datasets: [{
                backgroundColor: [],
                data: []
            }],
        }
      },
    },
    options: {
      type: Object,
      default() {
        return {
          responsive: true,
          maintainAspectRatio: false,
          cutoutPercentage: 70,
          legend: {
            position: 'right',
            labels: {
              generateLabels: (chart) => {
                let possibleLabels = [];

                Chart.defaults.global.legend.labels.generateLabels(chart)[0].text.forEach((label) => {
                  possibleLabels.push({
                    datasetIndex: 0,
                    hidden: false,
                    text: `${label.split(':')[0]} ${label.split(':')[1]}`,
                    fillStyle: `${label.split(':')[2]}`,
                    pointStyle: 'bold'
                  })
                });
                return possibleLabels;
              }
            }
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            },
          }
        };
      },
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
