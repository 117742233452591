//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import moment from 'moment';
import countriesJson from '../../../../src/json/countries.json'

export default {
    name: 'LogsTable',
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            page: 1,
            pageSize: 10,
            pageNo: 1,
            total: 1
        }
    },
    computed: {
        lastPage() {
            return Math.ceil(this.data.length / parseInt(this.pageSize, 10));
        },
        ...mapGetters({
            loading: 'verif8/loading'
        })
    },
    methods: {
        setPage(page) {
            this.page = page;
        },
        handlePaginationChange(action) {
            let page = this.pageNo;
            if (action === 'next') {
                page += 1;
            }

            if (action === 'prev' && page) {
                page -= 1;
            }

            this.pageNo = Number(page);
        },
        setPageSize(size) {
            this.pageSize = size;
        },
        genericSort(a, b, column) {
            return a[column] - b[column];
        },
        getPaginatedData() {
            const start = (this.pageNo - 1) * this.pageSize;
            return this.data.slice(start, start + this.pageSize);
        },
        getTimeFormat(dT) {
            return moment(dT).format('HH:mm');
        },
        sortDate(a, b) {
            return new Date(a.TimeFrom) - new Date(b.TimeFrom);
        },
        formatDateTime(tz, delimeter) {
            return moment.utc(tz).format(`MMM. DD, YYYY ${delimeter} h:mm A (UTC Z)`);
        },
        hasSMSChannel(channelType) {
            return channelType.toLowerCase().indexOf('sms') >= 0;
            
        },
        hasViberChannel(channelType) {
            return channelType.toLowerCase().indexOf('viber') >= 0;
        },
        getCountryName(code) {
            return countriesJson.find(country => country.value === code)?.name;
        }
    },
    mounted() {
    }
}
