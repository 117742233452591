//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'ExportModal',

  props: {
    showDlModal: {
      type: Boolean,
      default() {
        return false;
      },
    }
  },

  data() {

    return {

    };
  },

  computed: {
    ...mapGetters({
      
    }),
  },

  watch: {

  },

  mounted() {},

  methods: {
    closeDialog() {
      this.showDlModal = false;
      this.$emit('close');
    },
  },
};
