<template>
  <section id="verif8-dashboard" v-loading="loading">
    <div class="flex inline-block w-full">
      <h1 class="w-4/5 text-xl text-color font-semibold page-title">
        {{ $t('verif8.title.dashboard') }}
      </h1>
      <div class="w-2/5 text-right">
        <span class="text-right dark:text-white">SMS {{ `${channelFilter.find(v => v.value === 'vb').enabled ? '| Viber' : ''}` }}</span>
        <el-divider
          direction="vertical"
          class="bg-grey"
        />
        <el-select
          size="small"
          filterable
          class="w-1/2 items-center date-range-select"
          v-model="dateRange"
          @change="changeDate"
        >
        <el-option
          v-for="filter in resultFilters" 
          :key="filter.value"
          :label="filter.label"
          :value="filter.value" />
        </el-select>
      </div>
    </div>
    <div class="pt-5 m5-2">
        <div class="flex items-center mt-2 ml-2">
          <h3 class="text-md">At a glance</h3>
          <div class="ml-auto flex items-center">
            <el-tooltip
              :content="$t('actions.refresh')"
              placement="top"
              class="text-right"
            >
              <span
                class="icon-novo-refresh text-base cursor-pointer hover-icon"
                @click="refresh"
              />
            </el-tooltip>
          </div>
        </div>

        <div class="flex inline-block w-full pt-3 pb-3" v-if="currentData && currentData.summary && typeof currentData.summary.SmsCountTotal !== undefined">
          <div class="bg-white h-36 w-1/6 mr-1 rounded dark:bg-slate-800 summary-card">
            <h3 class="text-sm font-normal text-grey-dark">Details</h3>
            <h3 class="text-sm font-bold mt-3">Subaccount</h3>
              <span class="text-xs" style="color: #666666">{{ subAccountDetails.SubAccountId }}</span>
            <h3 class="text-sm font-bold mt-3">SenderId</h3>
              <span class="text-xs" style="color: #666666">Verif8</span>
          </div>
          <div class="bg-white h-36 w-1/6 mx-1 rounded summary-card">
            <h3 class="text-xs font-normal text-grey-dark">Total OTP messages</h3>
            <div class="flex items-center">
              <h3 class="text-md font-bold mt-3">{{ currentData && currentData.summary && currentData.summary.SmsCountTotal ? currentData.summary.SmsCountTotal : 0 }}</h3>
              <div class="ml-auto mt-5 text-right flex flex-col">
                <div
                  class="flex items-center"
                  :style="`color: ${currentData && currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'flat' ? '#666666' :
                    currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'down' ? '#CB2233' : '#127440'}`"
                >
                  <svg
                    v-if="currentData && currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'flat'"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <rect
                      width="8"
                      height="8"
                      rx="2"
                      fill="#666666"
                    />
                  </svg>
                  <i
                    v-else
                    :class="`${currentData && currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'up'
                      ? 'icon-novo-arrow-solid-up' : 'icon-novo-arrow-solid-down'}`"
                  />
                  <span>{{ getPercentValue(prevData.summary.SmsCountTotal, currentData.summary.SmsCountTotal) }}</span>
                </div>
                <div>
                  <svg
                    v-if="currentData && currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'flat'"
                    width="34"
                    height="8"
                    viewBox="0 0 34 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.2 3.99999L26.2 4H20.2H14.2H8.20001H1.84212"
                      stroke="#666666"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <svg
                    v-else-if="currentData && currentData.summary && checkTrend(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal) === 'up'"
                    width="48"
                    height="12"
                    viewBox="0 0 48 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13477 8.12842L10.1889 7.03748C10.3915 7.01308 10.5966 7.0512 10.7768 7.14672L17.5851 10.7549C17.8747 10.9084 18.2212 10.9102 18.5125 10.7598L27.0674 6.3391C27.2094 6.26577 27.3668 6.2275 27.5265 6.2275L37.9286 6.2275C38.1248 6.2275 38.3166 6.16982 38.4802 6.06163L46.1348 0.999996"
                      stroke="#127440"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    v-else
                    width="48"
                    height="11"
                    viewBox="0 0 48 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.40091L10.0488 1.08112C10.2544 1.02841 10.4715 1.04249 10.6685 1.12132L17.5557 3.87619C17.7566 3.95655 17.9782 3.96958 18.1871 3.91331L26.7607 1.60446C27.0097 1.53742 27.2749 1.56911 27.501 1.69292L35.7886 6.23055C35.8396 6.25846 35.8929 6.28187 35.9479 6.30052L46.8652 9.99999"
                      stroke="#CB2233"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <br/>
            <span class="text-sm mt-5" :style="`color: ${currentData && currentData.summary && currentData.summary.SmsCountTotal === prevData.summary.SmsCountTotal || isNaN(currentData.summary.SmsCountTotal) || isNaN(prevData.summary.SmsCountTotal)  ? '#666666' :
                    currentData.summary.SmsCountTotal < prevData.summary.SmsCountTotal ? '#CB2233' : '#127440'}`">
              {{ formatValue(currentData.summary.SmsCountTotal, prevData.summary.SmsCountTotal, false) }}
            </span>
          </div>
          <div class="bg-white h-36 w-1/6 mx-1 rounded summary-card">
            <h3 class="text-xs font-normal text-grey-dark">
              Total Cost
              <el-tooltip
                  :raw-content="true"
                  placement="top"
                  class="text-left"
                >
                <div slot="content">
                  Total cost is the sum of:
                  <br/><br/>
                  <div>Conversion cost: <strong>{{ currentData.summary.ConversionCost }}</strong></div>
                  <div>Delivery cost: <strong>{{ currentData.summary.DeliveryCost }}</strong></div>
                </div>
                <i
                  ref="widget-help"
                  role="button"
                  class="ml-1 icon-novo-support nv-widget__user-icons cursor-pointer"
                />
                </el-tooltip>
            </h3>
            <div class="flex items-center">
              <h3 class="text-md font-bold mt-3">{{ typeof currentData.summary.DeliveryCost !== undefined && typeof currentData.summary.ConversionCost !== undefined ? `${defaultCurrency} ${(parseFloat(currentData.summary.DeliveryCost) + parseFloat(currentData.summary.ConversionCost)).toFixed(2)}` : '0' }}</h3>
              <div class="ml-auto mt-5 text-right flex flex-col">
                <div
                  class="flex items-center"
                  :style="`color: ${currentData.summary.DeliveryCost === prevData.summary.DeliveryCost ? '#666666' :
                    currentData.summary.DeliveryCost < prevData.summary.DeliveryCost ? '#CB2233' : '#127440'}`"
                >
                  <svg
                    v-if="currentData.summary.DeliveryCost === prevData.summary.DeliveryCost"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <rect
                      width="8"
                      height="8"
                      rx="2"
                      fill="#666666"
                    />
                  </svg>
                  <i
                    v-else
                    :class="`${currentData.summary.DeliveryCost > prevData.summary.DeliveryCost
                      ? 'icon-novo-arrow-solid-up' : 'icon-novo-arrow-solid-down'}`"
                  />
                  <span>{{ getPercentValue(prevData.summary.DeliveryCost, currentData.summary.DeliveryCost)}}</span>
                </div>
                <div>
                  <svg
                    v-if="currentData.summary.DeliveryCost === prevData.summary.DeliveryCost"
                    width="34"
                    height="8"
                    viewBox="0 0 34 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.2 3.99999L26.2 4H20.2H14.2H8.20001H1.84212"
                      stroke="#666666"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <svg
                    v-else-if="currentData.summary.DeliveryCost > prevData.summary.DeliveryCost"
                    width="48"
                    height="12"
                    viewBox="0 0 48 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13477 8.12842L10.1889 7.03748C10.3915 7.01308 10.5966 7.0512 10.7768 7.14672L17.5851 10.7549C17.8747 10.9084 18.2212 10.9102 18.5125 10.7598L27.0674 6.3391C27.2094 6.26577 27.3668 6.2275 27.5265 6.2275L37.9286 6.2275C38.1248 6.2275 38.3166 6.16982 38.4802 6.06163L46.1348 0.999996"
                      stroke="#127440"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    v-else
                    width="48"
                    height="11"
                    viewBox="0 0 48 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.40091L10.0488 1.08112C10.2544 1.02841 10.4715 1.04249 10.6685 1.12132L17.5557 3.87619C17.7566 3.95655 17.9782 3.96958 18.1871 3.91331L26.7607 1.60446C27.0097 1.53742 27.2749 1.56911 27.501 1.69292L35.7886 6.23055C35.8396 6.25846 35.8929 6.28187 35.9479 6.30052L46.8652 9.99999"
                      stroke="#CB2233"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <br/>
            <span class="text-sm mt-5" :style="`color: ${currentData.summary.DeliveryCost === prevData.summary.DeliveryCost || isNaN(currentData.summary.DeliveryCost) || isNaN(prevData.summary.DeliveryCost) ? '#666666' :
                    currentData.summary.DeliveryCost < prevData.summary.DeliveryCost ? '#CB2233' : '#127440'}`">
              {{ `${formatValue(parseFloat(currentData.summary.DeliveryCost) + parseFloat(currentData.summary.ConversionCost), parseFloat(prevData.summary.DeliveryCost) + parseInt(prevData.summary.ConversionCost))}` }}
            </span>
          </div>
          <div class="bg-white h-36 w-1/6 mx-1 rounded summary-card">
            <h3 class="text-xs font-normal text-grey-dark">Delivery Rate</h3>
            <div class="flex items-center">
              <h3 class="text-md font-bold mt-3">{{ currentData.summary.DeliveryRate ? currentData.summary.DeliveryRate : 0}}%</h3>
              <div class="ml-auto mt-5 text-right flex flex-col">
                <div
                  class="flex items-center"
                  :style="`color: ${checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'flat' ? '#666666' :
                    checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'down' ? '#CB2233' : '#127440'}`"
                >
                  <svg
                    v-if="checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'flat'"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <rect
                      width="8"
                      height="8"
                      rx="2"
                      fill="#666666"
                    />
                  </svg>
                  <i
                    v-else
                    :class="`${checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'up'
                      ? 'icon-novo-arrow-solid-up' : 'icon-novo-arrow-solid-down'}`"
                  />
                  <span> {{ getPercentValue(prevData.summary.DeliveryRate, currentData.summary.DeliveryRate) }} </span>
                </div>
                <div>
                  <svg
                    v-if="checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'flat'"
                    width="34"
                    height="8"
                    viewBox="0 0 34 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.2 3.99999L26.2 4H20.2H14.2H8.20001H1.84212"
                      stroke="#666666"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <svg
                    v-else-if="checkTrend(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) === 'up'"
                    width="48"
                    height="12"
                    viewBox="0 0 48 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13477 8.12842L10.1889 7.03748C10.3915 7.01308 10.5966 7.0512 10.7768 7.14672L17.5851 10.7549C17.8747 10.9084 18.2212 10.9102 18.5125 10.7598L27.0674 6.3391C27.2094 6.26577 27.3668 6.2275 27.5265 6.2275L37.9286 6.2275C38.1248 6.2275 38.3166 6.16982 38.4802 6.06163L46.1348 0.999996"
                      stroke="#127440"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    v-else
                    width="48"
                    height="11"
                    viewBox="0 0 48 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.40091L10.0488 1.08112C10.2544 1.02841 10.4715 1.04249 10.6685 1.12132L17.5557 3.87619C17.7566 3.95655 17.9782 3.96958 18.1871 3.91331L26.7607 1.60446C27.0097 1.53742 27.2749 1.56911 27.501 1.69292L35.7886 6.23055C35.8396 6.25846 35.8929 6.28187 35.9479 6.30052L46.8652 9.99999"
                      stroke="#CB2233"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <br/>
            <span class="text-sm mt-5" :style="`color: ${currentData.summary.DeliveryRate === prevData.summary.DeliveryRate || isNaN(currentData.summary.DeliveryRate) ||  isNaN(prevData.summary.DeliveryRate) ? '#666666' :
                    currentData.summary.DeliveryRate < prevData.summary.DeliveryRate ? '#CB2233' : '#127440'}`">
              {{ formatValue(currentData.summary.DeliveryRate, prevData.summary.DeliveryRate) }}
            </span>
          </div>
          <div class="bg-white h-36 w-1/6 mx-1 rounded summary-card">
            <h3 class="text-xs font-normal text-grey-dark">Conversion Rate</h3>
            <div class="flex items-center">
              <h3 class="text-md font-bold mt-3">{{ !isNaN(currentData.summary.ConversionRate) ? currentData.summary.ConversionRate : 0 }}%</h3>
              <div class="ml-auto mt-5 text-right flex flex-col">
                <div
                  class="flex items-center"
                  :style="`color: ${checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'flat' ? '#666666' :
                    checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'down' ? '#CB2233' : '#127440'}`"
                >
                  <svg
                    v-if="checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'flat'"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    class="mr-1"
                  >
                    <rect
                      width="8"
                      height="8"
                      rx="2"
                      fill="#666666"
                    />
                  </svg>
                  <i
                    v-else
                    :class="`${checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'up'
                      ? 'icon-novo-arrow-solid-up' : 'icon-novo-arrow-solid-down'}`"
                  />
                  <span> {{ getPercentValue(prevData.summary.ConversionRate, currentData.summary.ConversionRate) }} </span>
                </div>
                <div>
                  <svg
                    v-if="checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'flat'"
                    width="34"
                    height="8"
                    viewBox="0 0 34 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M32.2 3.99999L26.2 4H20.2H14.2H8.20001H1.84212"
                      stroke="#666666"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <svg
                    v-else-if="checkTrend(currentData.summary.ConversionRate, prevData.summary.ConversionRate) === 'up'"
                    width="48"
                    height="12"
                    viewBox="0 0 48 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.13477 8.12842L10.1889 7.03748C10.3915 7.01308 10.5966 7.0512 10.7768 7.14672L17.5851 10.7549C17.8747 10.9084 18.2212 10.9102 18.5125 10.7598L27.0674 6.3391C27.2094 6.26577 27.3668 6.2275 27.5265 6.2275L37.9286 6.2275C38.1248 6.2275 38.3166 6.16982 38.4802 6.06163L46.1348 0.999996"
                      stroke="#127440"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                  <svg
                    v-else
                    width="48"
                    height="11"
                    viewBox="0 0 48 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 3.40091L10.0488 1.08112C10.2544 1.02841 10.4715 1.04249 10.6685 1.12132L17.5557 3.87619C17.7566 3.95655 17.9782 3.96958 18.1871 3.91331L26.7607 1.60446C27.0097 1.53742 27.2749 1.56911 27.501 1.69292L35.7886 6.23055C35.8396 6.25846 35.8929 6.28187 35.9479 6.30052L46.8652 9.99999"
                      stroke="#CB2233"
                      stroke-width="1.8"
                      stroke-linecap="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <br/>
            <span class="text-sm mt-5" :style="`color: ${currentData.summary.ConversionRate === prevData.summary.ConversionRate || isNaN(currentData.summary.ConversionRate) || isNaN(prevData.summary.ConversionRate) ? '#666666' :
                    currentData.summary.ConversionRate < prevData.summary.ConversionRate ? '#CB2233' : '#127440'}`">
              {{ formatValue(currentData.summary.ConversionRate, prevData.summary.ConversionRate) }}
            </span>
          </div>
          <div class="bg-white h-36 w-1/2 ml-1 rounded summary-card">
            <h3 class="text-sm font-bold">Destination Countries</h3>
            <div class="my-5">
              <DestinationCountryChart :key="nextTickKey" :width="100" :height="90" :chartData="getDestinationCountries()"/>
            </div>
          </div>
        </div>
        
        <hr class="mt-2 mb-2 nv-widget__divider-full">
        <div class="flex items-center mt-8 ml-2">
          <h3 class="text-md">Details</h3>
          <div class="ml-auto flex items-center">
            <el-tooltip
              :content="$t('actions.refresh')"
              placement="top"
              class="text-right"
            >
              <span
                class="icon-novo-refresh text-base cursor-pointer hover-icon"
                @click="refresh"
              />
            </el-tooltip>
          </div>
        </div>
        <div class="bg-white mt-5 mb-5 w-full rounded relative graph-container">
          <div class="flex items-center graph-container-header">
            <div class="flex items-center">
              <h4>Converted vs Non-converted</h4>
              <el-tooltip
                  :raw-content="true"
                  placement="top-start"
                  class="text-left pl-2"
                >
                <div slot="content">
                  <strong>Converted</strong>: OTPs that have been successfully<br/> received and validated by the recipient,<br/> completing the intended verification process. <br/><br/><strong>Non-converted</strong>: OTPs that were either not<br/> received by the recipient or were not validated,<br/> resulting in a failed verification attempt.
                </div>
                  <span
                    class="icon-novo-faq text-base cursor-pointer hover-icon"
                  />
              </el-tooltip>
            </div>
            <div class="flex justify-end">
              <el-select
                size="small"
                filterable
                class="w-1/2 items-center"
                :change="changeConversionChannel"
                v-model="conversion.channel">
                <el-option
                  v-for="item in channelFilter"
                  v-if="item.enabled"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value" />
              </el-select>
            </div>
          </div>
          <ChannelDeliveryConversionStats v-loading="refreshLoading" :key="nextTickKey" :chartData="getChannelConversionStats(this.filters.channel)"/>
        </div>
        <div class="bg-white mt-5 mb-5 w-full rounded relative graph-container">
          <div class="flex items-center graph-container-header">
            <div class="flex items-center">
              <h4 class="text-sm">Delivered vs Undelivered</h4>
              <div class="flex items-center">
                <el-tooltip
                  :raw-content="true"
                  placement="top-start"
                  class="text-left pl-2"
                >
                <div slot="content">
                  <strong>Delivered</strong>: OTPs that have been successfully sent <br/>and received by the recipient's device.
                  <br/><br/>
                  <strong>Undelivered</strong>: OTPs that were sent but failed to<br/> reach the recipient's device, often due to network <br/> issues or incorrect contact information.
                </div>
                  <span
                    class="icon-novo-faq text-base cursor-pointer hover-icon"

                  />
                </el-tooltip>
              </div>
            </div>
            
            <div class="flex justify-end">
              <el-select
                size="small"
                filterable
                class="w-1/2 items-center"
                :change="changeDeliveryChannel"
                v-model="delivery.channel">
                <el-option
                  v-for="item in channelFilter"
                  v-if="item.enabled"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value" />
              </el-select>
            </div>
          </div>
          <div class="h-1/2">
            <ChannelDeliveryConversionStats v-loading="refreshLoading" :key="nextTickKey" :chartData="getChannelDeliveryStats(this.filters.channel)"/>
          </div>
        </div>
    </div>
  </section>
</template>

<script>

import { mapActions, mapGetters } from 'vuex';
import DestinationCountryChart from './DestinationCountryChart.vue';
import ChannelDeliveryConversionStats from './ChannelDeliveryConversionStats.vue';
import countriesJson from '../../../../src/json/countries.json';
import _ from 'lodash'

export default {
  name: 'Dashboard',
  components: {
    ChannelDeliveryConversionStats,
    DestinationCountryChart,
  },

  data() {
    return {
      showApiKey: false,
      dateRange: '24H',
      maskedApiKey: '',
      delivery: {
        channel: 'sm'
      },
      conversion: {
        channel: 'sm'
      },
      defaultCurrency: 'USD',
      nextTickKey: '',
      filters: {
        channel: 'sm',
        dateFrom: '',
        dateTo: '',
        tzOffset: 0,
        subAccountUid: '',
        timeInterval: 1440, // equivalent to 'day'
      },
      channelFilter: [
        {
          label: 'SMS',
          value: 'sm',
          enabled: true,
        },
        {
          label: 'Viber',
          value: 'vb',
          enabled: false,
        }
      ],
      resultFilters: [
        {
          label: 'Last 24 Hours',
          value: '24H',
        },
        {
          label: 'Last 7 Days',
          value: '7D',
        },
        {
          label: 'Last 31 Days',
          value: '31D',
        },
      ]
    };
  },

  computed: {
    ...mapGetters({
      subAccountDetails: 'verif8/getSubAccountDetails',
      apiKey: 'verif8/getApiKey',
      loading: 'verif8/loading',
      currentData: 'verif8/getCurrentData',
      prevData: 'verif8/getPreviousData',
    }),    
  },

  async created() {
     try {
       await this.getVerif8Details();
       if (!this.subAccountDetails.SubAccountId && !this.apiKey.ApiKey) {
          this.$router.push({ name: '/verif8' });
          return;
       }
       // Get Account Wallet Currency
       const userData = JSON.parse(localStorage['CPV3_User']);
       this.defaultCurrency = userData.Currency;
       this.filters.subAccountUid = this.subAccountDetails.SubAccountUid;
      // Call Reports on default filters
      await this.changeDate('24H' || this.dateRange);
     } catch (_) {
      this.$message.error(`Dashboard: Unable to process request at this time. ${JSON.stringify(_)}`);
     }
  },

  async mounted() {
    if (this.currentData && this.prevData) {
      const { sms } = this.currentData;
    }
  },

  methods: {
    ...mapActions({
      getVerif8Details: 'verif8/getVerif8Details',
      getPreviousData: 'verif8/getPreviousData',
      getCurrentData: 'verif8/getCurrentData',
    }),
    async refresh() {
      this.refreshLoading = true;
      this.changeDeliveryChannel('sm');
      this.changeConversionChannel('sm');
      this.refreshLoading = false;
      this.getDataSummary();
    },
    getDataSummary(attr) {
      const { sms } = this.currentData;
      const value = _.find(sms.summary, { label: attr })?.value || 0;
      return value;
    },
    getPercentValue(oldValue, newValue){
      const percent = Math.ceil(Math.abs((newValue - oldValue)/oldValue * 100));
      if (percent == 'Infinity') {
        return '100%';
      } else if (isNaN(percent)) {
        return '0%';
      } else {
          return `${percent}%`;
      }
    },
    formatValue(currentValue, prevValue, formatToFixed = true) {
      if (currentValue === prevValue || (isNaN(prevValue) || isNaN(currentValue))) {
        return 'No Change';
      } else if (currentValue < prevValue) {
        return formatToFixed ? `-${Math.abs(currentValue - prevValue).toFixed(2)} ` : `-${Math.abs(currentValue - prevValue)}`;
      } else {
        return formatToFixed ? `+${Math.abs(currentValue - prevValue).toFixed(2)}` : `+${Math.abs(currentValue - prevValue)}`;
      }
    },
    async changeDeliveryChannel(channel) {
      this.delivery.channel = channel;
      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    async changeConversionChannel(channel, chartMode) {
      this.conversion.channel = channel;
      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    async changeDate(t) {
      let tempFromDate = new Date();
      this.filters.dateTo = new Date(tempFromDate).toISOString().slice(0, 14) + '00';
      this.filters.timeInterval = 1440; // default
      switch(t) {
        case '7D':
          tempFromDate = new Date().setDate(new Date().getDate() - 7);
          break;
        case '31D':
          tempFromDate = new Date().setDate(new Date().getDate() - 31);
          break;
        case '24H':
        default:
          tempFromDate = new Date().setDate(new Date().getDate() - 1);
          // Override when 24H is selected
          this.filters.timeInterval = 60;
          break;
      }
      this.dateRange = t;
      this.filters.dateFrom = new Date(tempFromDate).toISOString().slice(0, 14) + '00';

      await this.getCurrentData(this.filters);
      await this.getPreviousData(this.filters);
      this.nextTickKey = `${this.filters.dateFrom}-${this.filters.dateTo}-${this.filters.channel}`;
    },
    generateHexColors() {
      return ['#41B883', '#E46651', '#00D8FF', '#FE2B61', '#424b0f', '#B23683', '#0B49AA', '#E0E0E0', '#009B89', '#D83848', '#FFC693', '#D9991C', '#D2F3E1', '#98CDC2', '#99BBF3', '#246FE5', '#D1B9D8', '#BC3F5F']
    },
    checkTrend(currentData, prevData) {
      if (currentData > prevData) {
        return 'up';
      } else if (currentData < prevData) {
        return 'down';
      } else {
        return 'flat';
      }
    },
    // Dashboard Reports
    getDestinationCountries() {
      const dataTemplate = {
        labels: [],
        datasets: [{
          label: [],
          backgroundColor: this.generateHexColors(),
          data: [],
        }]
      }
      const sortedCountries =  _.sortBy(this.currentData.summary.Countries, obj => Object.values(obj)[0]).reverse();
      sortedCountries.forEach(country => {

        const cName = countriesJson.find(c => c.value === Object.keys(country).shift())?.name || '';
        const countryCount = Object.values(country).shift() || 0;
        dataTemplate.labels.push(cName);
        // For Formatting
        dataTemplate.datasets[0].label.push(`${countryCount}:${cName}:${dataTemplate.datasets[0].backgroundColor[dataTemplate.labels.indexOf(cName)]}` );
        dataTemplate.datasets[0].data.push(countryCount);
      })

      return dataTemplate;
    },
    getChannelConversionStats(channel = 'sm') {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Converted',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Non-converted',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          }
        ]
      }
      if (this.currentData && this.currentData?.dataByDate?.length === 0) {
        return dataTemplate;
      }

      if (this.conversion.channel === 'sm' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
            this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(/20\d+/, ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }

          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'SmsConverted') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'SmsCountNonConverted') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      } else if (this.conversion.channel === 'vb' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(new Date().getFullYear(), ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }
          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'ViberCountConverted') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'ViberCountNonConverted') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      }

      return dataTemplate;
    },
    getChannelDeliveryStats(channel = 'sm') {
      const dataTemplate = {
        labels: [],
        datasets: [
          {
            label: 'Delivered',
            backgroundColor: '#009B89',
            borderColor: '#009B89',
            fill: false,
            tension: 0.1,
            data: []
          },
          {
            label: 'Undelivered',
            backgroundColor: '#B23683',
            borderColor: '#B23683',
            fill: false,
            tension: 0.1,
            data: []
          }
        ]
      }

      if (this.delivery.channel === 'sm' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          this.filters.timeInterval === 60 ? dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toTimeString().slice(0, 5)) : dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(/20\d+/, ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }

          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'SmsCountDelivered') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'SmsCountUndelivered') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      } else if (this.delivery.channel === 'vb' && this.currentData.dataByDate) {
        for (let i = 0; i < this.currentData.dataByDate.length; i++) {
          dataTemplate.labels.push(new Date(this.currentData.dataByDate[i].date).toDateString().replace(new Date().getFullYear(), ''));
          if (this.currentData.dataByDate[i].values.length === 0) {
            dataTemplate.datasets[0].data.push(0);
            dataTemplate.datasets[1].data.push(0);
          }
          this.currentData.dataByDate[i].values.map((v, idx) => {
            if (v.label && v.label === 'ViberCountDelivered') {
              dataTemplate.datasets[0].data.push(v.value);
            } else if (v.label && v.label === 'ViberCountDelivered') {
              dataTemplate.datasets[1].data.push(v.value);
            }
          });
        }
      }

      return dataTemplate;
    },
  },
};
</script>

<style scoped lang="scss">
  .card {
    padding: 5px;
    background-color: #f1f1f1;
    border: 1px solid #f6f6f6;
    border-radius: 5px;
  }
  .card-warning {
    padding: 5px;
    background-color: #F8AE1A;
    border: 1px solid #F8AE1A;
    border-radius: 5px;
  }

  #verif8-dashboard {
    .date-range-select {
      ::v-deep .el-input__inner{
        background-color: #e0e0e0 !important;
      }
    }

    .summary-card {
      padding: 0.75rem;
      border: 1px solid #e0e0e0;
      height: 150px;
    }

    .graph-container {
      padding: 1rem;

      &-header {
        padding-bottom: 1rem;
        justify-content: space-between;
      }
    }
  }
</style>
